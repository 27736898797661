import { ReactNode, useState } from 'react'
import {
  Card,
  Form,
  Rate,
  Button,
  Input,
  Radio,
  FormInstance,
  message,
} from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { WeekSchedulePicker } from '../../../../../../../components/WeekSchedulePicker'
import axios from 'clients/axios'
import { ENDPOINTS } from '../../../../../../../utils'
import { RestaurantFormData } from '../types'

interface Props {
  actionButtons: ReactNode
  hasRatingUrl: boolean
  form: FormInstance<RestaurantFormData>
  isDisabled: boolean
}

const Other = ({ actionButtons, hasRatingUrl, form, isDisabled }: Props) => {
  const [loadingRating, setLoadingRating] = useState<boolean>(false)
  const [rating, setRating] = useState<number | undefined>(
    form.getFieldValue(['restaurant', 'starRating']),
  )

  const updateRating = async () => {
    const restaurantId = form.getFieldValue(['restaurant', 'id'])
    if (!restaurantId) {
      return
    }
    setLoadingRating(true)
    try {
      const updatedRating = (
        await axios.post(ENDPOINTS.restaurantUpdateRating, {
          id: restaurantId,
        })
      ).data
      setRating(updatedRating)
      setLoadingRating(false)
    } catch (e) {
      setLoadingRating(false)
      message.error("Couldn't refresh Google rating")
    }
  }

  return (
    <Card
      size="small"
      title="Other"
      style={{ marginBottom: 16 }}
      extra={actionButtons}
    >
      <Form.Item label="Rating" name={['restaurant', 'starRating']}>
        {rating}
        {rating && (
          <Rate
            style={{ marginLeft: 16, marginRight: 16 }}
            allowHalf
            disabled
            value={rating}
          />
        )}
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          size={'small'}
          loading={loadingRating}
          onClick={updateRating}
          disabled={!hasRatingUrl}
        >
          Refresh
        </Button>
      </Form.Item>
      <Form.Item
        name={['restaurant', 'ratingUrl']}
        label="URL for Google rating"
      >
        <Input disabled={isDisabled} />
      </Form.Item>
      <Form.Item name={['restaurant', 'priceRange']} label="Price range">
        <Radio.Group>
          <Radio value={1} disabled={isDisabled}>
            $
          </Radio>
          <Radio value={2} disabled={isDisabled}>
            $$
          </Radio>
          <Radio value={3} disabled={isDisabled}>
            $$$
          </Radio>
          <Radio value={4} disabled={isDisabled}>
            $$$$
          </Radio>
          <Radio value={5} disabled={isDisabled}>
            $$$$$
          </Radio>
        </Radio.Group>
      </Form.Item>
      <div style={{ display: 'flex' }}>
        <WeekSchedulePicker
          formKeys={['restaurant', 'openingHours']}
          getFormState={form.getFieldsValue}
          setFormState={form.setFieldsValue}
          disabled={isDisabled}
        />
        <WeekSchedulePicker
          formKeys={['restaurant', 'kitchenHours']}
          getFormState={form.getFieldsValue}
          setFormState={form.setFieldsValue}
          disabled={isDisabled}
          label="Kitchen opening hours:"
        />
      </div>
    </Card>
  )
}

export default Other
