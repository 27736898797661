import { AssetType } from '../types/interfaces'

export const ENDPOINTS = {
  login: '/auth/login',
  logout: '/auth/logout',
  passwordTokenValidate: '/auth/',
  passwordReset: '/auth/reset-password',
  users: '/users',
  userCreate: '/users/create',
  userUpdate: '/users/update',
  userDisable: (id: string) => `/users/${id}/disable`,
  userEnable: (id: string) => `/users/${id}/enable`,
  userUpdateAccess: (id: string) => `/users/${id}/access`,
  restaurantsAll: '/restaurants/all',
  restaurantsSearch: (search: string, page: number) =>
    `/restaurants/search?${search ? `&search=${search}` : ''}${
      page ? `&page=${page}` : ''
    }`,
  restaurantCreate: '/restaurants/create',
  restaurantUpdate: '/restaurants/update',
  restaurantPictureUpload: (id: string) => `/restaurants/${id}/upload-picture`,
  restaurantAddUser: (restaurantId: string, userId: string) =>
    `/restaurants/${restaurantId}/add-user/${userId}`,
  restaurantDeleteUser: (restaurantId: string, userId: string) =>
    `/restaurants/${restaurantId}/remove-user/${userId}`,
  restaurantUsers: (id: string) => `/restaurants/${id}/users`,
  accesses: '/accesses',
  tags: '/tags',
  tagsSearch: '/tags/search',
  tagCreate: '/tags/create',
  cuisines: '/cuisines',
  cuisineCreate: '/cuisines/create',
  menuItemsCreate: '/menu-items/create',
  menuItemsUpdate: '/menu-items/update',
  menuItemsDelete: '/menu-items/delete',
  menuSectionsDelete: '/menu-sections/delete',
  assetUpload: (type: AssetType, priority: number | null) =>
    `/assets/upload/${type}/${priority !== null ? `${priority}` : '0'}`,
  restaurantUpdateRating: '/restaurants/update-rating',
  getRestaurant: (id: string | number) => `/restaurants/${id}`,
  interiorDesigns: '/interior-designs',
  syncGoogleRatings: '/restaurants/update-all-ratings',
  menuUpdate: '/menus/update',
}

export const days = [
  { label: 'Pon', value: 'Mon' },
  { label: 'Wt', value: 'Tue' },
  { label: 'Śr', value: 'Wed' },
  { label: 'Czw', value: 'Thu' },
  { label: 'Pt', value: 'Fri' },
  { label: 'Sob', value: 'Sat' },
  { label: 'Nd', value: 'Sun' },
]

export const readableProvidersMapping: { [deliveryKey: string]: string } = {
  pyszne: 'Pyszne.pl',
  glovo: 'Glovo',
  uber: 'Uber Eats',
  wolt: 'Wolt',
  restaurant: 'By store',
  self: 'Self-Pickup',
}

// TODO needs some adjustments, write proper labels in code review pls
export const readableRestaurantFeatures: { [featureKey: string]: string } = {
  garden: 'Garden',
  vip: 'VIP lounge',
  business: 'Business meetings',
  parking: 'Parking for visitors',
  wifi: 'Wi-Fi',
  pets: 'Animal friendly',
  disabled: 'Disabled friendly',
  kids: 'Children room',
  smoking: 'Smoking room',
  quiet: 'Quiet',
  lively: 'Lively',
}

export const readableRestaurantBadges: { [badgeKey: string]: string } = {
  design: 'Super Design',
}
