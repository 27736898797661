import React, { useEffect, useState } from 'react'
import { Divider, Input, Modal } from 'antd'
import { LocationPicker } from '../../../../components/LocationPicker'
import { axios } from '../../../../clients'
import {
  CreateRestaurantDTO,
  GeocoderResponse,
  RestaurantDTO,
} from '../../../../types/interfaces'
import { Position } from 'geojson'
import { notNull } from '../../../../types/guards'
import { ENDPOINTS } from '../../../../utils'
import { useHistory } from 'react-router-dom'

interface Props {
  setVisible: (value: boolean) => void
  visible?: boolean
  data?: RestaurantDTO
  refetch?: () => void
}

type State = {
  name: string
  location: {
    pin: {
      type: 'Point'
      coordinates: Position | null
    }
    city?: string
    address?: string
    postcode?: string
  }
}

export const RestaurantModal = ({
  visible,
  setVisible,
  data: entity,
  refetch,
}: Props) => {
  const [state, setState] = useState<State>({
    name: entity?.name ?? '',
    location: {
      pin: {
        type: 'Point',
        coordinates: entity?.location.pin.coordinates ?? null,
      },
      city: undefined,
      address: undefined,
      postcode: undefined,
    },
  })

  useEffect(() => {
    if (entity) {
      setState(entity)
    }
  }, [entity])
  const history = useHistory()
  const onOk = () => {
    const { name, location } = state
    if (notNull(location.pin.coordinates) && name.length > 0) {
      // TODO split update and create to use different payload shapes
      const payload: CreateRestaurantDTO = {
        name,
        location: {
          ...location,
          // @ts-ignore
          id: entity?.location.id ?? undefined,
        },
      }
      const promise = !entity
        ? axios.post(ENDPOINTS.restaurantCreate, payload)
        : axios.put(ENDPOINTS.restaurantUpdate, { ...payload, id: entity.id })

      promise
        .then((response) => {
          const { id } = response.data
          if (refetch) {
            refetch()
          }
          setVisible(false)
          history.push(`restaurants/${id}`)
        })
        .catch((error) => console.log(error))
    }
  }

  const onCancel = () => setVisible(false)

  const setGeocoderAddress = (result: GeocoderResponse) => {
    if (result.place_type.length !== 1 || 'address' !== result.place_type[0]) {
      return
    }

    setState({
      ...state,
      location: {
        ...state.location,
        pin: { ...state.location.pin, coordinates: result.center },
        city: result.place_name.split(',')?.[1]?.match(/[\p{Letter}]+/gu)?.[0],
        address: result.place_name.split(',')?.[0],
        postcode: result.place_name.match(/\d\d-\d\d\d/g)?.[0],
      },
    })
  }

  return (
    <Modal
      title={entity ? `Edit restaurant - ${entity.id}` : 'Add Restaurant'}
      centered
      onOk={onOk}
      visible={visible}
      onCancel={onCancel}
      destroyOnClose={true}
    >
      <Input
        value={state.name}
        onChange={(e) => setState({ ...state, name: e.target.value })}
        placeholder="Name"
      ></Input>
      <Divider />
      {/* <MapContainer /> */}
      <LocationPicker
        position={state.location.pin.coordinates ?? undefined}
        onChange={(coordinates) =>
          setState({
            ...state,
            location: {
              ...state.location,
              pin: {
                ...state.location.pin,
                coordinates,
              },
            },
          })
        }
        onAddressSelect={setGeocoderAddress}
      />

      <div>
        <div>Longitude: {state.location.pin.coordinates?.[0]}</div>
        <div>Latitude: {state.location.pin.coordinates?.[1]}</div>
        <div>City: {state.location.city}</div>
        <div>Address: {state.location.address}</div>
        <div>Postcode: {state.location.postcode}</div>
      </div>
    </Modal>
  )
}

export default RestaurantModal
