/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, message, Row, Select, Space, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Search from 'antd/lib/input/Search'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ListedRestaurantDTO } from '../../../../types/interfaces'
import CreateRestaurantModal from './RestaurantModal'
import './Restaurants.css'
import { RESTAURANTS } from 'clients/apollo'
import { useQuery } from '@apollo/client'
import { useDebounce } from './useDebounce'
import DashboardLayout from 'components/DashboardLayout'
import { VisibilityFilter } from '../../../../types/types'

const Restaurants = () => {
  const history = useHistory()
  const [dialogVisible, setDialogVisible] = useState(false)
  const [searchState, setSearchState] = useState({ search: '', page: 1 })
  const debouncedSearchQuery = useDebounce(searchState.search, 500)
  const [visibilityFilter, setVisibilityFilter] =
    useState<VisibilityFilter>('all')

  const { loading, error, data } = useQuery(RESTAURANTS, {
    variables: {
      search: debouncedSearchQuery,
      visibilityFilter,
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (error) {
      message.error(error)
    }
  }, [error])

  const dataSource: ListedRestaurantDTO[] = data?.restaurantsAll || []

  const onAdd = () => setDialogVisible(true)

  const { Option } = Select

  const columns: ColumnsType<ListedRestaurantDTO> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Address',
      dataIndex: ['location', 'address'],
      key: 'address',
      responsive: ['md'],
    },
    {
      title: 'City',
      dataIndex: ['location', 'city'],
      key: 'city',
      responsive: ['md'],
    },
    {
      title: 'Location',
      key: 'location',
      responsive: ['md'],
      render: (text: any, record: ListedRestaurantDTO) => {
        return <Space size="middle">{record.location.pin.coordinates}</Space>
      },
    },
    {
      title: 'Visibility',
      key: 'visibility',
      render: (text: any, record: ListedRestaurantDTO) => (
        <Space size="middle">
          {record.isVisible ? (
            <Tag color="green">public</Tag>
          ) : (
            <Tag color="volcano">hidden</Tag>
          )}
        </Space>
      ),
    },
  ]

  return (
    <DashboardLayout header="Restaurants">
      <div>
        <Row style={{ marginBottom: 16, width: '100%' }}>
          <Col xs={14} md={10}>
            <Search
              placeholder="Search restaurants"
              onChange={(e) => {
                setSearchState({ search: e.target.value, page: 1 })
              }}
              loading={loading}
            />
          </Col>
          <Col xs={4} md={6}>
            <Select
              defaultValue="all"
              style={{ width: '15vw' }}
              onChange={(value) => setVisibilityFilter(value)}
            >
              <Option value="all">All</Option>
              <Option value="public">Public</Option>
              <Option value="hidden">Hidden</Option>
            </Select>
          </Col>
          <Col xs={6} md={8} style={{ textAlign: 'right' }}>
            <Button onClick={onAdd}>Add Restaurant</Button>
          </Col>
        </Row>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey="id"
          rowClassName="restaurants-row"
          pagination={{
            onChange: (page) =>
              setSearchState({ search: searchState.search, page }),
            current: searchState.page,
          }}
          onRow={(record) => {
            return {
              onClick: () => history.push(`restaurants/${record.id}`),
            }
          }}
        />
        <CreateRestaurantModal
          visible={dialogVisible}
          setVisible={setDialogVisible}
        />
      </div>
    </DashboardLayout>
  )
}

export default Restaurants
