import React, { useState } from 'react'
import { Button, Col, Divider, Form, Input, message, Row } from 'antd'
import DashboardLayout from 'components/DashboardLayout'
import { axios } from 'clients'
import { ENDPOINTS } from 'utils'
import { DeletionConfirm as PopupConfirmButton } from 'components/DeletionConfirm'

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 20 },
}

const azureFunctionUrl =
  'https://yumito-wolt-scrapper.azurewebsites.net/api/YumitoWoltScrapper'

const Tools = () => {
  const handleSubmit = async (formData: any) => {
    const link = document.createElement('a') //create 'a' element
    link.setAttribute('href', `${azureFunctionUrl}?url=${formData.url}`) //replace "file" with link to file you want to download
    link.setAttribute('download', 'file') // replace "file" here too
    link.click() //virtually click <a> element to initiate download
  }
  const [isSyncEnabled, setIsSyncEnabled] = useState(true)

  const handleSyncRatings = () => {
    axios
      .put(ENDPOINTS.syncGoogleRatings)
      .then(() => {
        message.success('Triggered Google ratings sync')
        setIsSyncEnabled(false)
      })
      .catch(() => message.error('Failed to trigger Google ratings sync'))
  }

  return (
    <DashboardLayout header="Tools">
      <Form
        {...formLayout}
        layout="vertical"
        name="yumito-wolt-scrapper"
        onFinish={handleSubmit}
      >
        <Row gutter={24}>
          <Col xs={24} md={16}>
            <Form.Item
              label="Wolt URL"
              name={'url'}
              rules={[
                {
                  required: true,
                  type: 'url',
                  message:
                    'Give valid Wolt URL - e.g. https://wolt.com/pl/pol/warsaw/restaurant/bosko-tuthai',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {isSyncEnabled && (
        <>
          <Divider />
          <div style={{ width: 'fit-content' }}>
            <PopupConfirmButton
              id={null}
              onConfirm={handleSyncRatings}
              delay={0}
              buttonCaption="Synchronize Google Ratings"
              title="Before proceeding, make sure sync is not underway already"
              type="button"
            />
          </div>
        </>
      )}
    </DashboardLayout>
  )
}

export default Tools
