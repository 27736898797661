import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import LogRocket from 'logrocket'
import App from './containers/App'
import reportWebVitals from './utils/reportWebVitals'

import './index.css'

const IS_SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLED === 'true'

if (IS_SENTRY_ENABLED) {
  Sentry.init({
    dsn: 'https://789a1d0cfb6944ab86e2ec97db37eda8@o1104892.ingest.sentry.io/6132395',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

export const IS_LOG_ROCKET_ENABLED =
  process.env.REACT_APP_LOGROCKET_ENABLED === 'true'

if (IS_LOG_ROCKET_ENABLED) {
  LogRocket.init('oohmou/yumito')
}

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
