import { useState } from 'react'
import { Button, Empty, message, Form } from 'antd'
import { QueryFunction, useQuery } from 'react-query'
import { useQuery as useGraphqlQuery } from '@apollo/client'
import Menu from './components/Menu'
import { axios } from '../../../../../../clients'
import { Spinner } from 'components/Spinner'
import SectionsModal from './modals/SectionsModal'
import ItemsModal from './modals/ItemsModal'
import { Availability, MenuDTO, MenuItemDTO } from 'types/interfaces'
import { GET_LUNCHES } from '../../../../../../clients/apollo'
import { GetLunchesResponse } from '../../../../../../types/types'
import Lunches from './components/Lunches'

const Container = ({ children }: any) => (
  <div>
    {/* <Title level={3}>Menu</Title> */}
    {/* <div>{children}</div> */}
    {children}
  </div>
)

type Params = {
  queryKey: [string, { id: number }]
}

const fetchMenu: QueryFunction<MenuDTO> = async (params: Params) => {
  const [, { id }] = params.queryKey
  const { data } = await axios.get(`restaurants/${id}/menu`)
  return data
}

export const RestaurantMenu = ({
  id,
  defaultAvailability,
}: {
  id: string
  defaultAvailability: Availability | undefined
}) => {
  const [sectionsModalVisible, setSectionsModalVisible] = useState(false)
  const [itemsModalVisible, setItemsModalVisible] = useState(false)
  const [selectedItem, setSelectedItem] =
    useState<MenuItemDTO | undefined>(undefined)

  const [isCreatingMenu, setCreatingMenu] = useState(false)
  const { isLoading, error, data, refetch } = useQuery<MenuDTO, Error>(
    ['restaurant', { id }, 'menu'],
    fetchMenu,
    { retry: 3 },
  )

  const {
    data: getLunchesResponse,
    loading: lunchesLoading,
    refetch: refetchLunches,
  } = useGraphqlQuery<GetLunchesResponse>(GET_LUNCHES, {
    variables: { restaurantId: id },
  })

  const [form] = Form.useForm()

  const createMenu = async () => {
    setCreatingMenu(true)
    try {
      await axios.post(`restaurants/${id}/menu/create`)
      refetch()
      setCreatingMenu(false)
    } catch {
      setCreatingMenu(false)
      message.error('Error creating new menu :(')
    }
  }

  if (isLoading || lunchesLoading) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  if (error || !data) {
    return (
      <Container>
        <Empty description="No menu found.">
          <Button type="primary" onClick={createMenu} loading={isCreatingMenu}>
            Add menu
          </Button>
        </Empty>
      </Container>
    )
  }

  const lunches = getLunchesResponse?.lunchesByRestaurant

  return (
    <Container>
      <Menu
        menuId={data.id}
        setSectionModalVisible={setSectionsModalVisible}
        setItemsModalVisible={setItemsModalVisible}
        setSelectedItem={setSelectedItem}
        form={form}
      />
      <SectionsModal
        menuId={data.id}
        setVisible={setSectionsModalVisible}
        visible={sectionsModalVisible}
        defaultAvailability={defaultAvailability}
      />
      <ItemsModal
        closeModal={() => setItemsModalVisible(false)}
        visible={itemsModalVisible}
        menuItem={selectedItem}
        form={form}
        menu={data}
        refetchMenu={refetch}
      />
      <Lunches
        lunches={lunches}
        refetchLunches={refetchLunches}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        form={form}
        restaurantId={id}
        lunchesLoading={lunchesLoading}
        setItemsModalVisible={setItemsModalVisible}
      />
    </Container>
  )
}

export default RestaurantMenu
