import React from 'react'
import { Button, List, message, Space, FormInstance } from 'antd'
import { MessageOutlined, LikeOutlined, StarOutlined } from '@ant-design/icons'
import { ENDPOINTS } from 'utils'
import { Availability, MenuItemDTO, MenuSectionDTO } from 'types/interfaces'
import axios from 'clients/axios'
import { DeletionConfirm } from 'components/DeletionConfirm'

const IconText = ({ icon, text }: any) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
)

export const MenuItem = (
  item: MenuItemDTO,
  setItemsModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedItem: React.Dispatch<
    React.SetStateAction<MenuItemDTO | undefined>
  >,
  form: FormInstance<any>,
  refetchMenuItems: () => any,
  section?: MenuSectionDTO,
) => (
  <List.Item
    key={item.title}
    actions={[
      <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
      <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
      <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
      <Button
        onClick={() => {
          setSelectedItem({ ...item, section })
          form.resetFields()
          setItemsModalVisible(true)
        }}
      >
        Edit
      </Button>,
      <DeletionConfirm
        title="Are you sure you want to delete this item?"
        id={item.id}
        onConfirm={(id: string | number | null) =>
          axios
            .delete(ENDPOINTS.menuItemsDelete, { data: { id } })
            .then(refetchMenuItems)
            .catch(() => message.error("Couldn't delete the item!"))
        }
        buttonCaption="Delete"
        delay={0}
      />,
    ]}
    extra={
      item.image ? <img width={272} alt="logo" src={item.image.url} /> : <div />
    }
  >
    <List.Item.Meta
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      title={<a href={'#'}>{item.title}</a>}
      description={`${item.price} ${item.currency} ${
        item.amount?.unit && item.amount?.quantity
          ? ` / ${item.amount.quantity} ${item.amount.unit}`
          : ''
      }`}
    />
    {item.description}
  </List.Item>
)

export const parseSectionAvailability = (sectionData: MenuSectionDTO) => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  return days
    .map((day) => {
      const availability =
        sectionData.availability[day.toLowerCase() as keyof Availability]
      return availability ? `${day}: ${availability}` : ''
    })
    .join(' ')
}
