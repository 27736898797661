import { QueryFunction } from 'react-query'
import { axios } from '../clients'
import { MenuSectionDTO, MenuItemDTO, RestaurantDTO } from '../types/interfaces'
import { ENDPOINTS } from './constants'

type Params = {
  queryKey: [string, { id: number }]
}

export const restaurantKey = ({ restaurantId }: { restaurantId: string }) => [
  'restaurant',
  { id: restaurantId },
]

export const fetchRestaurant: QueryFunction<RestaurantDTO> = async (
  params: Params,
) => {
  const [, { id }] = params.queryKey
  const { data } = await axios.get<RestaurantDTO>(ENDPOINTS.getRestaurant(id))
  return data
}

export const sectionsKey = ({ menuId }: { menuId: string }) => [
  'menu',
  { id: menuId },
  'sections',
]

export const fetchSections: QueryFunction<MenuSectionDTO[]> = async (
  params: Params,
) => {
  const [, { id }] = params.queryKey
  // TODO a section endpoint that fetches menu items with section information
  const { data } = await axios.get(`menus/${id}/sections?fields=[items]`)
  return data
}

export const sectionKey = ({ sectionId }: { sectionId: string }) => [
  'section',
  { id: sectionId },
]

export const fetchSection: QueryFunction<MenuSectionDTO> = async (
  params: Params,
) => {
  const [, { id }] = params.queryKey
  // TODO a section endpoint that fetches menu items with section information
  const { data } = await axios.get(`menu-sections/${id}`)
  return data
}

export const sectionItemsKey = ({ sectionId }: { sectionId: string }) => [
  'section',
  { id: sectionId },
  'items',
]

export const fetchSectionItems: QueryFunction<MenuItemDTO[]> = async (
  params: Params,
) => {
  const [, { id }] = params.queryKey
  // TODO a section endpoint that fetches menu items with section information
  const { data } = await axios.get(`menu-sections/${id}/menu-items`)
  return data
}
