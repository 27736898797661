import React from 'react'
import { List, FormInstance } from 'antd'
import { MenuItemDTO, MenuSectionDTO } from 'types/interfaces'
import { MenuItem } from './MenuItem'

interface Props {
  items: MenuItemDTO[]
  loading: boolean
  setItemsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedItem: React.Dispatch<React.SetStateAction<MenuItemDTO | undefined>>
  section?: MenuSectionDTO
  form: FormInstance<any>
  refetchMenuItems: () => any
}

export const MenuItemList = ({
  items,
  loading,
  setItemsModalVisible,
  setSelectedItem,
  section,
  form,
  refetchMenuItems,
}: Props) => {
  return (
    <List
      itemLayout="vertical"
      loading={loading}
      size="large"
      dataSource={items}
      renderItem={(items) =>
        MenuItem(
          items,
          setItemsModalVisible,
          setSelectedItem,
          form,
          refetchMenuItems,
          section,
        )
      }
    />
  )
}
