import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import 'antd/dist/antd.css'
import './App.css'
import 'antd-country-phone-input/dist/index.css'

import { Dashboard, Login, PasswordReset } from '../../pages'
import { ConfigProvider } from 'antd-country-phone-input'
import en from 'world_countries_lists/data/en/world.json'
import { ApolloProvider } from '@apollo/client'
import { apollo } from '../../clients'
import AuthRequired from './AuthRequired'

const queryClient = new QueryClient()

function App() {
  return (
    <ConfigProvider locale={en}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={apollo}>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/reset-password">
                <PasswordReset />
              </Route>
              <AuthRequired>
                <Route path="/">
                  <Dashboard />
                </Route>
              </AuthRequired>
            </Switch>
          </ApolloProvider>
        </QueryClientProvider>
      </Router>
    </ConfigProvider>
  )
}

export default App
