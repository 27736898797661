import { Dispatch, SetStateAction } from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  TimePicker,
} from 'antd'
import moment from 'moment'
import { CREATE_LUNCH } from '../../../../../../../clients/apollo'

interface Props {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  restaurantId: string
  refetchLunch: () => void
}

interface FormValues {
  title: string
  date: moment.Moment | null
  servingHours: [moment.Moment, moment.Moment] | null
}

const LunchModal = ({
  isVisible,
  setIsVisible,
  restaurantId,
  refetchLunch,
}: Props) => {
  const [createLunch] = useMutation(CREATE_LUNCH)

  const onFinish = async (values: FormValues) => {
    if (values.date && values.servingHours) {
      const fromTime = values.servingHours[0].format('HH:mm')
      const toTime = values.servingHours[1].format('HH:mm')
      const date = values.date.toDate()

      try {
        await createLunch({
          variables: {
            lunchInput: {
              title: values.title,
              date,
              servingHours: `${fromTime}-${toTime}`,
              restaurantId,
              lunchItems: [],
            },
          },
        })
        refetchLunch()
      } catch (error) {
        message.error('Error creating lunch')
      } finally {
        setIsVisible(false)
      }
    }
  }

  const initialValues: FormValues = {
    title: '',
    date: null,
    servingHours: null,
  }

  return (
    <Modal
      title="Add lunch"
      centered
      visible={isVisible}
      destroyOnClose={true}
      footer={null}
      onCancel={() => setIsVisible(false)}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={initialValues}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Lunch title required' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: 'Date required' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Serving hours"
          name="servingHours"
          rules={[{ required: true, message: 'Serving hours required' }]}
        >
          <TimePicker.RangePicker format="HH:mm" minuteStep={15} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Add lunch
        </Button>
      </Form>
    </Modal>
  )
}

export default LunchModal
