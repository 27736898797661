/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from '@apollo/client'
import { Button, Col, Input, message, Row, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { INTERIOR_DESIGNS } from 'clients/apollo'
import DashboardLayout from 'components/DashboardLayout'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { InteriorDesignDTO } from '../../../../types/interfaces'
import { useDebounce } from '../Restaurants/useDebounce'
import EditInteriorDesignModal from './EditInteriorDesignModal'

const columns = (
  setModalState: React.Dispatch<React.SetStateAction<InteriorDesignDTO | null>>,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
): ColumnsType<InteriorDesignDTO> => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: any, record) => (
      <Space size="middle">
        <a
          onClick={() => {
            setModalState(record)
            setIsModalVisible(true)
          }}
        >
          Edit
        </a>
      </Space>
    ),
  },
]

const InteriorDesigns = () => {
  const [modalState, setModalState] = useState<InteriorDesignDTO | null>(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const [searchState, setSearchState] = useState({ search: '', page: 1 })
  const debouncedSearchQuery = useDebounce(searchState.search, 250)

  const { error, data, refetch } = useQuery(INTERIOR_DESIGNS, {
    variables: { search: debouncedSearchQuery },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (error) {
      message.error(error.message)
    }
  }, [error])

  const onSearch = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchState({ search: event.target.value, page: 1 })

  return (
    <DashboardLayout header="Interior designs">
      <div>
        <Row style={{ marginBottom: 16, width: '100%' }}>
          <Col xs={14} md={10}>
            <Input placeholder="Search interior design" onChange={onSearch} />
          </Col>
          <Col xs={10} md={14} style={{ textAlign: 'right' }}>
            <Button
              onClick={() => {
                setModalState(null)
                setIsModalVisible(true)
              }}
            >
              Add interior design
            </Button>
            <EditInteriorDesignModal
              modalState={modalState}
              setModalState={setModalState}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              refetch={refetch}
            />
          </Col>
        </Row>
        <Table
          dataSource={data?.interiorDesigns}
          columns={columns(setModalState, setIsModalVisible)}
          rowKey="id"
          pagination={{
            onChange: (page) => setSearchState({ ...searchState, page }),
            current: searchState.page,
          }}
        />
      </div>
    </DashboardLayout>
  )
}

export default InteriorDesigns
