import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Input, Button, Typography, Alert } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { axios } from '../../../clients'
import { ENDPOINTS } from '../../../utils'
import { useHistory, useLocation } from 'react-router-dom'

const Container = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Box = styled.div`
  max-width: 300px;
  flex-grow: 1;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const Login = () => {
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const onSubmit = (formValues: any) => {
    const { password } = formValues
    const id = query.get('id')
    const token = query.get('token')
    if (id?.length && token?.length) {
      console.log({ id, token, password })
      axios
        .patch(ENDPOINTS.passwordReset, { id, token, password })
        .then(({ data }) => {
          history.push('/')
        })
        .catch(() => {
          setErrorMessage('Something went wrong...')
        })
    } else if (!token?.length) {
      setErrorMessage('Error: missing token')
    } else {
      setErrorMessage('Error: missing id')
    }
  }

  return (
    <Container>
      <Box>
        <Typography.Title style={{ textAlign: 'center' }}>
          Password reset
        </Typography.Title>
        <Form
          name="password-reset-form"
          className="password-reset-form"
          onFinish={onSubmit}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your new password',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="passwordRepeat"
            rules={[
              {
                required: true,
                message: 'Both fields have to match!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value === getFieldValue('password')) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Passwords do not match!'))
                },
              }),
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Confirm your password"
            />
          </Form.Item>
          <Flex>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Reset password
              </Button>
            </Form.Item>
          </Flex>
        </Form>
        {errorMessage !== null && (
          <Alert message={errorMessage} type="error" showIcon />
        )}
      </Box>
    </Container>
  )
}

export default Login
