import { message, Modal } from 'antd'
import { axios } from 'clients'

import { ENDPOINTS } from 'utils/constants'
import { User } from './RestaurantOwners'
interface Props {
  id: string
  userId: string
  setVisible: (value: boolean) => void
  setStaff: (value: User[]) => void
  visible: boolean
}
export const ConfirmDeleteModal = (props: Props) => {
  const confirmDelete = async () => {
    try {
      await axios
        .delete(ENDPOINTS.restaurantDeleteUser(props.id, props.userId))
        .then(() =>
          axios.get(ENDPOINTS.restaurantUsers(props.id)).then(({ data }) => {
            props.setStaff(data)
          }),
        )
      message.success('User removed successfully!')
    } catch (e) {
      message.error(`Couldn't delete user.`)
    }
  }

  return (
    <Modal
      title={'Remove user from restaurant'}
      centered
      okText={'Remove'}
      visible={props.visible}
      destroyOnClose={true}
      onOk={() => {
        confirmDelete()
        props.setVisible(false)
      }}
      onCancel={() => {
        props.setVisible(false)
      }}
      width={640}
    >
      Do you want to delete user from restaurant?
    </Modal>
  )
}
