/* eslint-disable jsx-a11y/anchor-is-valid */
import { FetchResult, useQuery } from '@apollo/client'
import { Button, Col, Input, message, Row, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { TAGS } from 'clients/apollo'
import DashboardLayout from 'components/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { TagDTO } from 'types/interfaces'
import { DELETE_TAG } from 'clients/apollo'

import { useDebounce } from '../Restaurants/useDebounce'
import TagModal from './TagModal'
import { DeletionConfirm } from 'components/DeletionConfirm'

// TODO move this interface to better place
interface Tag {
  id: string
  name: string
}

const columns = (
  setModalState: React.Dispatch<React.SetStateAction<Tag | null>>,
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  deleteTag: ({
    variables,
  }: {
    variables: { input: { id: string } }
  }) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>,
  refetch: () => void,
): ColumnsType<Tag> => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: any, record) => (
      <>
        <Space size="middle">
          <Button
            style={{ padding: 0, marginRight: 16 }}
            type="link"
            onClick={() => {
              setModalState(record)
              setIsModalVisible(true)
            }}
          >
            Edit
          </Button>
        </Space>
        <Space size="middle">
          <DeletionConfirm
            title="Are you sure you want to delete this tag? It will be removed from all of the items!"
            id={record.id}
            onConfirm={() => {
              deleteTag({ variables: { input: { id: record.id } } })
                .catch(() =>
                  message.error(`Tag ${record.name} can't be deleted`),
                )
                .finally(() => refetch())
            }}
            buttonCaption="Delete"
            delay={0}
            type="link"
          />
        </Space>
      </>
    ),
  },
]

const Tags = () => {
  const [modalState, setModalState] = useState<TagDTO | null>(null)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const [searchState, setSearchState] = useState({ search: '', page: 1 })
  const debouncedSearchQuery = useDebounce(searchState.search, 250)

  const { error, data, refetch } = useQuery(TAGS, {
    variables: { search: debouncedSearchQuery },
    fetchPolicy: 'cache-and-network',
  })

  const [deleteTag] = useMutation(DELETE_TAG)

  useEffect(() => {
    if (error) {
      message.error(error.message)
    }
  }, [error])

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchState({ search: e.target.value, page: 1 })

  return (
    <DashboardLayout header="Tags">
      <div>
        <Row style={{ marginBottom: 16, width: '100%' }}>
          <Col xs={14} md={10}>
            <Input placeholder="Search tags" onChange={onSearch} />
          </Col>
          <Col xs={10} md={14} style={{ textAlign: 'right' }}>
            <Button
              onClick={() => {
                setModalState(null)
                setIsModalVisible(true)
              }}
            >
              Add Tag
            </Button>
            <TagModal
              modalState={modalState}
              setModalState={setModalState}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              refetch={refetch}
            />
          </Col>
        </Row>
        <Table
          dataSource={data?.tags ?? []}
          columns={columns(
            setModalState,
            setIsModalVisible,
            deleteTag,
            refetch,
          )}
          rowKey="id"
          pagination={{
            onChange: (page) => setSearchState({ ...searchState, page }),
            current: searchState.page,
          }}
        />
      </div>
    </DashboardLayout>
  )
}

export default Tags
