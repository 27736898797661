import { Dispatch, ReactNode, SetStateAction } from 'react'
import { Card, Form, Input } from 'antd'
import CountryPhoneInput from 'antd-country-phone-input'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { LocationDTO } from '../../../../../../../types/interfaces'

interface Props {
  actionButtons: ReactNode
  setLocation: Dispatch<SetStateAction<LocationDTO>>
  isDisabled: boolean
}

const GeneralInfo = ({ actionButtons, setLocation, isDisabled }: Props) => (
  <Card
    size="small"
    title="General"
    style={{ marginBottom: 16 }}
    extra={actionButtons}
  >
    <Form.Item label="Id" name={['restaurant', 'id']}>
      <Input disabled={true} />
    </Form.Item>
    <Form.Item
      name={['restaurant', 'name']}
      label="Name"
      validateTrigger="onChange"
      rules={[
        {
          required: true,
          message: "Restaurant's name is required",
        },
        { type: 'string' },
        {
          max: 256,
          message: "Restaurant's name cannot be longer than 256 characters",
        },
      ]}
    >
      <Input disabled={isDisabled} />
    </Form.Item>
    <Form.Item
      name={['restaurant', 'description']}
      label="Description"
      validateTrigger="onChange"
      rules={[
        { type: 'string' },
        {
          max: 256,
          message:
            "Restaurant's description cannot be longer than 256 characters",
        },
      ]}
    >
      <Input.TextArea disabled={isDisabled} />
    </Form.Item>
    <Form.Item name={['restaurant', 'location', 'id']} noStyle />
    <Form.Item
      name={['restaurant', 'location', 'address']}
      label="Address"
      validateTrigger="onChange"
      rules={[
        { type: 'string' },
        {
          max: 256,
          message: "Restaurant's address cannot be longer than 256 characters",
        },
      ]}
    >
      <Input
        disabled={isDisabled}
        onChange={(event) =>
          setLocation((prevLocation) => ({
            ...prevLocation,
            address: event.currentTarget.value,
          }))
        }
      />
    </Form.Item>
    <Form.Item
      name={['restaurant', 'location', 'city']}
      label="City"
      validateTrigger="onChange"
      rules={[
        { type: 'string' },
        {
          max: 256,
          message:
            "Restaurant's city name cannot be longer than 256 characters",
        },
      ]}
    >
      <Input
        disabled={isDisabled}
        onChange={(event) =>
          setLocation((prevLocation) => ({
            ...prevLocation,
            city: event.currentTarget.value,
          }))
        }
      />
    </Form.Item>
    <Form.Item name={['restaurant', 'location', 'postcode']} label="Postcode">
      <Input
        disabled={isDisabled}
        onChange={(event) =>
          setLocation((prevLocation) => ({
            ...prevLocation,
            postcode: event.currentTarget.value,
          }))
        }
      />
    </Form.Item>
    <Form.Item
      name={['restaurant', 'phoneNumber']}
      label="Phone number"
      rules={[
        () => ({
          validator(_, value) {
            if (
              !value?.phone ||
              isPossiblePhoneNumber(`+${value.code}${value.phone}`)
            ) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('Enter a valid phone number!'))
          },
        }),
      ]}
      style={{
        cursor: isDisabled ? 'not-allowed' : 'auto',
      }}
    >
      <CountryPhoneInput
        disabled={isDisabled}
        style={{
          pointerEvents: isDisabled ? 'none' : 'auto',
        }}
      />
    </Form.Item>
    <Form.Item
      name={['restaurant', 'webPageUrl']}
      label="Web page"
      validateTrigger="onChange"
      rules={[
        { type: 'string' },
        {
          max: 256,
          message:
            "Restaurant's web page URL cannot be longer than 256 characters",
        },
      ]}
    >
      <Input disabled={isDisabled} />
    </Form.Item>
  </Card>
)

export default GeneralInfo
