import React, { useState } from 'react'
import { Button, Col, Form, FormInstance, Input, Row } from 'antd'
import { WeekSchedulePicker } from 'components/WeekSchedulePicker'

interface Props {
  form: FormInstance<any>
  onFinish: (values: any) => void
  actionType: 'Submit' | 'Add'
}

export const SectionsForm = ({ form, onFinish, actionType }: Props) => {
  const [isScheduleHidden, setIsScheduleHidden] = useState(actionType === 'Add')
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      style={{ marginBottom: 16 }}
    >
      <Form.Item
        label="Description"
        name="description"
        style={{ marginBottom: 8 }}
        rules={[{ required: true, message: 'Description required!' }]}
      >
        <Input />
      </Form.Item>

      {isScheduleHidden && (
        <>
          Section's availability is by default set to restaurant's opening hours
          <Button
            onClick={() => setIsScheduleHidden(false)}
            style={{ marginTop: 8 }}
          >
            Edit availability
          </Button>
        </>
      )}
      <WeekSchedulePicker
        formKeys={['availability']}
        getFormState={form.getFieldsValue}
        setFormState={form.setFieldsValue}
        isHidden={isScheduleHidden}
      />

      <Row style={{ width: '100%' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {actionType}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
