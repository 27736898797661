import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'

const Box = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Spinner = () => (
  <Box>
    <Spin />
  </Box>
)
