import React, { useState } from 'react'
import { Alert, FormInstance } from 'antd'
import { QueryObserverResult, RefetchOptions, useQuery } from 'react-query'
import {
  fetchSection,
  fetchSectionItems,
  sectionItemsKey,
  sectionKey,
} from 'utils'
import { Availability, MenuItemDTO, MenuSectionDTO } from 'types/interfaces'
import EditSectionModal from '../../modals/EditSectionModal'
import { MenuItemList } from './MenuItemList'
import { SectionInfo } from './SectionInfo'

export const parseSectionAvailability = (sectionData: MenuSectionDTO) => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  return days
    .map((day) => {
      const availability =
        sectionData.availability[day.toLowerCase() as keyof Availability]
      return availability ? `${day}: ${availability}` : ''
    })
    .join(' ')
}

interface Props {
  section: MenuSectionDTO
  setItemsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedItem: React.Dispatch<React.SetStateAction<MenuItemDTO | undefined>>
  form: FormInstance<any>
  menuId: string
  setSelectedSection: React.Dispatch<
    React.SetStateAction<MenuSectionDTO | null>
  >
  refetchSections: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<MenuSectionDTO[], Error>>
  menuSections: MenuSectionDTO[] | undefined
}

export const MenuItemsLoader = ({
  section,
  setItemsModalVisible,
  setSelectedItem,
  form,
  menuId,
  setSelectedSection,
  refetchSections,
  menuSections,
}: Props) => {
  const [sectionsEditVisible, setSectionsEditVisible] = useState(false)

  const {
    isLoading,
    error,
    data: menuItemsData,
    refetch: refetchMenuItems,
  } = useQuery<MenuItemDTO[], Error>(
    sectionItemsKey({ sectionId: section.id.toString() }),
    fetchSectionItems,
    { retry: 3 },
  )

  const { data: sectionData, refetch: refetchSection } = useQuery<
    MenuSectionDTO,
    Error
  >(sectionKey({ sectionId: section.id.toString() }), fetchSection, {
    retry: 3,
  })

  if (error) {
    return <Alert type="error" message="Couldnt load menu items." />
  }

  return (
    <div>
      {sectionData?.availability && (
        <SectionInfo
          section={section}
          setSectionsEditVisible={setSectionsEditVisible}
          setSelectedSection={setSelectedSection}
          menuId={menuId}
          menuSections={menuSections}
          refetchSections={refetchSections}
        />
      )}
      <MenuItemList
        loading={isLoading}
        items={menuItemsData || []}
        setItemsModalVisible={setItemsModalVisible}
        setSelectedItem={setSelectedItem}
        section={section}
        form={form}
        refetchMenuItems={refetchMenuItems}
      />
      {sectionData && (
        <EditSectionModal
          visible={sectionsEditVisible}
          setVisible={setSectionsEditVisible}
          sectionData={sectionData}
          menuId={menuId}
          fetchSection={refetchSection}
        />
      )}
    </div>
  )
}
