import { Dispatch, Fragment, SetStateAction, useState } from 'react'
import { Col, Row, Form, Button } from 'antd'
import {
  CuisineDTO,
  InteriorDesignDTO,
  RestaurantDTO,
} from '../../../../../../types/interfaces'
import GeneralInfo from './formSections/GeneralInfo'
import ActionButtons from './ActionButtons'
import Attributes from './formSections/Attributes'
import Status from './formSections/Status'
import Location from './formSections/Location'
import Other from './formSections/Other'
import Billing from './formSections/Billing'
import { parseRestaurantToInitialValues } from './helpers'
import { RestaurantFormData } from './types'

const formLayout = {
  labelCol: { span: 16 },
  wrapperCol: { span: 20 },
}

interface Props {
  restaurant: RestaurantDTO
  onSubmit: any
  isSaving?: boolean
  cuisines: CuisineDTO[]
  mainCuisine: CuisineDTO
  interiorDesigns: InteriorDesignDTO[]
  hasPrimaryPhoto: boolean
  enabledFormSection: string | undefined
  setEnabledFormSection: Dispatch<SetStateAction<string | undefined>>
}

export const DetailsForm = ({
  restaurant,
  onSubmit,
  isSaving,
  cuisines,
  interiorDesigns,
  hasPrimaryPhoto,
  enabledFormSection,
  setEnabledFormSection,
}: Props) => {
  const [form] = Form.useForm<RestaurantFormData>()
  const [selectedCuisines, setSelectedCuisines] = useState(
    restaurant?.cuisines?.map(({ name }) => name) ?? [],
  )
  const [location, setLocation] = useState(restaurant.location)

  const getActionButtons = (sectionName: string) => (
    <ActionButtons
      sectionName={sectionName}
      setFormToOriginalData={() => form.resetFields()}
      enabledFormSection={enabledFormSection}
      setEnabledFormSection={setEnabledFormSection}
      isSaving={!!isSaving}
    />
  )

  return (
    <Fragment>
      <Form
        {...formLayout}
        layout="vertical"
        name="restaurant-details"
        form={form}
        onFinish={onSubmit}
        validateTrigger="onSubmit"
        initialValues={{
          restaurant: parseRestaurantToInitialValues(restaurant),
        }}
      >
        <Row gutter={24}>
          <Col
            xs={24}
            md={11}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <GeneralInfo
                actionButtons={getActionButtons('generalInfo')}
                setLocation={setLocation}
                isDisabled={enabledFormSection !== 'generalInfo'}
              />
              <Attributes
                actionButtons={getActionButtons('attributes')}
                form={form}
                cuisines={cuisines}
                selectedCuisines={selectedCuisines}
                setSelectedCuisines={setSelectedCuisines}
                interiorDesigns={interiorDesigns}
                isDisabled={enabledFormSection !== 'attributes'}
              />
              <Status
                location={location}
                hasSelectedCuisine={selectedCuisines?.length > 0}
                hasPrimaryPhoto={hasPrimaryPhoto}
              />
            </div>
            <Form.Item
              wrapperCol={{
                ...formLayout.wrapperCol,
                xs: 24,
                style: { textAlign: 'right' },
              }}
            >
              <Button type="primary" htmlType="submit" loading={isSaving}>
                Submit
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} md={13}>
            <Location
              actionButtons={getActionButtons('location')}
              form={form}
              location={location}
              isDisabled={enabledFormSection !== 'location'}
            />
            <Other
              actionButtons={getActionButtons('other')}
              hasRatingUrl={!!restaurant.ratingUrl}
              form={form}
              isDisabled={enabledFormSection !== 'other'}
            />
            <Billing
              actionButtons={getActionButtons('billing')}
              isDisabled={enabledFormSection !== 'billing'}
            />
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default DetailsForm
