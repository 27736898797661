import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { AuthToken } from '../../types/types'

const AuthRequired = ({ children, ...rest }: any) => {
  const auth = useAuth()

  const isAuthenticated = () => {
    const tokens = auth?.token?.split('.')
    if (!tokens) {
      return false
    }
    const parsedTokenPayload = JSON.parse(atob(tokens[1])) as AuthToken
    const tokenExpireTime = parsedTokenPayload.exp
    const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000)
    return currentTimeInSeconds < tokenExpireTime
  }

  return (
    <Route
      {...rest}
      path="*"
      render={({ location }) =>
        isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default AuthRequired
