import DashboardLayout from 'components/DashboardLayout'
import React from 'react'
import { WorkInProgress } from '../../../../components'

const Home = () => (
  <DashboardLayout header="Home">
    <WorkInProgress />
  </DashboardLayout>
)

export default Home
