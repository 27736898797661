import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Card, Empty, Space, FormInstance } from 'antd'
import { useQuery } from 'react-query'

import { MenuItemDTO, MenuSectionDTO } from 'types/interfaces'
import { sectionsKey, fetchSections } from 'utils/queries'

import { MenuItemsLoader } from './MenuSection/MenuSection'

interface MenuCardProps {
  onSectionAdd?: () => void
  onItemAdd?: () => void
  children: React.ReactNode
  tabList?: any[]
  activeTabKey?: string
  onTabChange?: (key: any) => void
  title?: string
  onLunchDelete?: () => void
}

export const MenuCard = ({
  children,
  onSectionAdd,
  onItemAdd,
  activeTabKey,
  title,
  onLunchDelete,
  ...cardProps
}: MenuCardProps) => (
  <Card
    style={{ width: '100%' }}
    title={title ? title : 'Menu'}
    extra={
      <Space>
        {onSectionAdd && <Button onClick={onSectionAdd}>Add section</Button>}
        {activeTabKey && (
          <Button type="primary" onClick={onItemAdd}>
            Add item
          </Button>
        )}
        {onLunchDelete && <Button onClick={onLunchDelete}>Delete lunch</Button>}
      </Space>
    }
    activeTabKey={activeTabKey}
    {...cardProps}
  >
    {children}
  </Card>
)

interface Props {
  menuId: string
  // modal toggles
  setSectionModalVisible: Dispatch<SetStateAction<boolean>>
  setItemsModalVisible: Dispatch<SetStateAction<boolean>>
  setSelectedItem: Dispatch<SetStateAction<MenuItemDTO | undefined>>
  form: FormInstance<any>
}

const Menu = ({
  menuId,
  setSectionModalVisible,
  setItemsModalVisible,
  setSelectedItem,
  form,
}: Props): JSX.Element => {
  const [selectedSection, setSelectedSection] =
    useState<MenuSectionDTO | null>(null)

  const onTabChange = (id: string) =>
    setSelectedSection(
      menuSections?.find((section) => section.id.toString() === id) ?? null,
    )

  const { data: menuSections, refetch: refetchSections } = useQuery<
    MenuSectionDTO[],
    Error
  >(sectionsKey({ menuId }), fetchSections, { retry: 3 })

  // TODO loading state

  // TODO error state in case this cant be fetched

  useEffect(() => {
    if (!selectedSection && menuSections && menuSections.length > 0) {
      setSelectedSection(menuSections[0])
    }
  }, [selectedSection, menuSections])

  if (!menuSections || menuSections.length === 0) {
    return (
      <MenuCard onSectionAdd={() => setSectionModalVisible(true)}>
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<span>No sections in menu</span>}
        >
          <Button type="primary" onClick={() => setSectionModalVisible(true)}>
            Add new section
          </Button>
        </Empty>
      </MenuCard>
    )
  }

  const tabList = menuSections.map((section) => ({
    key: section.id.toString(),
    tab: section.description,
  }))

  return (
    <MenuCard
      onSectionAdd={() => setSectionModalVisible(true)}
      onItemAdd={() => {
        setSelectedItem(undefined)
        form.resetFields()
        setItemsModalVisible(true)
      }}
      tabList={tabList}
      activeTabKey={selectedSection?.id.toString() || tabList[0].key}
      onTabChange={onTabChange}
    >
      <MenuItemsLoader
        section={selectedSection || menuSections[0]}
        setItemsModalVisible={setItemsModalVisible}
        setSelectedItem={setSelectedItem}
        form={form}
        menuId={menuId}
        setSelectedSection={setSelectedSection}
        refetchSections={refetchSections}
        menuSections={menuSections}
      />
    </MenuCard>
  )
}

export default Menu
