import React from 'react'
import styled from 'styled-components'
import { grey } from '@ant-design/colors'

import { CoffeeOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

const { Title } = Typography

const Box = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const WorkInProgress = () => (
  <Box>
    <CoffeeOutlined
      style={{ fontSize: 32, marginBottom: 8, color: grey[3] }}
      className=".ant-typography.ant-typography-secondary"
    />
    <Title type="secondary" level={4}>
      Work in progress
    </Title>
  </Box>
)
