import { Button } from 'antd'

interface Props {
  sectionName: string
  setFormToOriginalData: () => void
  enabledFormSection: string | undefined
  setEnabledFormSection: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
  isSaving: boolean
}

const ActionButtons = ({
  sectionName,
  enabledFormSection,
  setFormToOriginalData,
  setEnabledFormSection,
  isSaving,
}: Props) => {
  if (sectionName === enabledFormSection) {
    return (
      <>
        <Button
          onClick={() => {
            setFormToOriginalData()
            setEnabledFormSection(undefined)
          }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSaving}
          style={{ marginLeft: 8 }}
        >
          Submit
        </Button>
      </>
    )
  }
  return (
    <Button
      type="primary"
      onClick={() => setEnabledFormSection(sectionName)}
      disabled={enabledFormSection !== undefined}
    >
      Edit
    </Button>
  )
}

export default ActionButtons
