/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Row, Space, Table } from 'antd'
import Search from 'antd/lib/input/Search'
import { ColumnsType } from 'antd/lib/table'
import DashboardLayout from 'components/DashboardLayout'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { axios } from '../../../../clients'
import { Access } from '../../../../types/interfaces'
import { ENDPOINTS } from '../../../../utils'
import CreateUserModal from './CreateUserModal'
import Checkbox from 'antd/es/checkbox'

// TODO move this interface to better place
interface User {
  name: string
  email: string
  id: string
  avatar: string
  isActive: boolean
}

const columns: ColumnsType<User> = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Access level',
    dataIndex: ['accessLevel', 'level'],
    key: 'level',
  },
  {
    title: 'Active',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (text: any, record) => (
      <Space size="middle">
        {' '}
        <Checkbox checked={record.isActive} />
      </Space>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: any, record) => (
      <Space size="middle">
        <Link to={`users/${record.id}`}>Edit</Link>
      </Space>
    ),
  },
]

const Users = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const [accesses, setAccesses] = useState<Access[]>([])

  const fetchUsers = () => {
    axios.get(ENDPOINTS.users).then(({ data }) => {
      setUsers(data)
    })
  }

  const fetchAccesses = () => {
    axios.get(ENDPOINTS.accesses).then(({ data }) => {
      setAccesses(data)
    })
  }

  useEffect(fetchUsers, [])
  useEffect(fetchAccesses, [])

  const onSearch = () => console.log('TODO search users')

  return (
    <DashboardLayout header="Users">
      <div>
        <Row style={{ marginBottom: 16, width: '100%' }}>
          <Col xs={14} md={10}>
            <Search
              placeholder="Search users"
              onSearch={onSearch}
              enterButton
            />
          </Col>
          <Col xs={10} md={14} style={{ textAlign: 'right' }}>
            <Button onClick={() => setModalVisible(true)}>Add User</Button>
            <CreateUserModal
              visible={modalVisible}
              setVisible={setModalVisible}
              refetch={fetchUsers}
              accesses={accesses}
            />
          </Col>
        </Row>
        <Table dataSource={users} columns={columns} rowKey="id" />
      </div>
    </DashboardLayout>
  )
}

export default Users
