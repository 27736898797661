import DashboardLayout from 'components/DashboardLayout'
import React from 'react'
import { WorkInProgress } from '../../../../components'

const Other = () => (
  <DashboardLayout header="Other">
    <WorkInProgress />
  </DashboardLayout>
)

export default Other
