import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form, Input, Modal } from 'antd'
import { axios } from '../../../../clients'
import { CreateCuisineDTO, CuisineDTO } from '../../../../types/interfaces'
import { ENDPOINTS } from '../../../../utils'
import { UPDATE_CUISINE } from 'clients/apollo'
import { useMutation } from '@apollo/client'

interface Props {
  setModalState: (value: CuisineDTO | null) => void
  modalState: CuisineDTO | null
  setIsModalVisible: (value: boolean) => void
  isModalVisible: boolean
  refetch: () => void
}

type State = {
  name: string
}

export const CuisineModal = ({
  modalState,
  setModalState,
  isModalVisible,
  setIsModalVisible,
  refetch,
}: Props) => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [state, setState] = useState<State>({
    name: '',
  })

  const inputRef = useRef<any>()

  const [form] = Form.useForm()

  useEffect(() => {
    setState({
      name: modalState ? modalState.name : '',
    })
    form.resetFields()
  }, [modalState, form, isModalVisible])

  const [updateCuisine] = useMutation(UPDATE_CUISINE)

  const handleCreate = (values: any) => {
    console.log(values)
    const { name } = state
    if (name.length > 0) {
      const payload: CreateCuisineDTO = {
        name,
      }

      setLoading(true)

      return axios.post(ENDPOINTS.cuisineCreate, payload)
    }

    return Promise.reject()
  }

  const handleUpdate = (values: any) => {
    const { name } = state
    if (name.length > 0) {
      const payload: CuisineDTO = {
        id: (modalState as CuisineDTO).id,
        name,
      }

      setLoading(true)

      return updateCuisine({ variables: { input: payload } })
    }

    return Promise.reject()
  }

  const onCancel = () => {
    setIsModalVisible(false)
    setModalState(null)
  }

  const handleOk = () =>
    form
      .validateFields()
      .catch((info) => {
        setLoading(false)
        console.log('Validate Failed:', info)
      })
      .then(
        (values): Promise<any> =>
          modalState ? handleUpdate(values) : handleCreate(values),
      )
      .then(() => {
        setLoading(false)
        form.resetFields()
        refetch()
        if (!modalState) {
          inputRef.current.focus()
        } else {
          onCancel()
        }
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })

  return (
    <Modal
      title={modalState ? 'Update cuisine' : 'Create cuisine'}
      centered
      visible={isModalVisible}
      confirmLoading={isLoading}
      onOk={handleOk}
      onCancel={onCancel}
      okText={modalState ? 'Update' : 'Create'}
      destroyOnClose={true}
    >
      <Form
        {...{
          labelCol: { span: 5 },
          wrapperCol: { span: 19 },
        }}
        form={form}
        onFinish={handleOk}
      >
        <Form.Item
          label="Cuisine name"
          name="name"
          rules={[{ required: true, message: 'Cuisine name required!' }]}
          initialValue={state.name}
        >
          <Input
            value={state.name}
            autoComplete="new-password"
            onChange={(e) => setState({ ...state, name: e.target.value })}
            placeholder="Cuisine"
            ref={inputRef}
          ></Input>
        </Form.Item>
        <Button htmlType="submit" style={{ display: 'none' }} />
      </Form>

      {error && (
        <Alert
          message={
            modalState
              ? 'This cuisine cannot be updated.'
              : 'This cuisine cannot be created.'
          }
          type="error"
          showIcon
        />
      )}
    </Modal>
  )
}

export default CuisineModal
