import React, { useState } from 'react'
import { Button, Form, TimePicker } from 'antd'

const hourValidator = () => ({
  validator(_: any, value: moment.Moment[]) {
    if (
      !value ||
      !value[0].isSame(value[1]) ||
      (value[0].isSame(value[1]) && !value[0].hour() && !value[0].minutes())
      // let's say, that 24h interval should be represented with 00-00
    ) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('Enter a valid interval!'))
  },
})

interface Props {
  formKeys: string[]
  getFormState: () => any
  setFormState: (form: any) => any
  isHidden?: boolean
  disabled?: boolean
  label?: string
}

export const WeekSchedulePicker = ({
  formKeys,
  getFormState,
  setFormState,
  isHidden,
  disabled,
  label,
}: Props) => {
  const [mondayValue, setMondayValue] = useState<any>()

  const copySchedule = () => {
    const form = getFormState()
    if (formKeys.length > 1) {
      form[formKeys[0]][formKeys[1]] = {
        mon: mondayValue,
        tue: mondayValue,
        wed: mondayValue,
        thu: mondayValue,
        fri: mondayValue,
        sat: mondayValue,
        sun: mondayValue,
      }
      setFormState(form)
    } else {
      form[formKeys[0]] = {
        mon: mondayValue,
        tue: mondayValue,
        wed: mondayValue,
        thu: mondayValue,
        fri: mondayValue,
        sat: mondayValue,
        sun: mondayValue,
      }
      setFormState(form)
    }
  }

  return (
    <Form.Item
      label={label ? label : 'Opening hours:'}
      style={{ display: isHidden ? 'none' : 'block' }}
    >
      <Form.Item
        label="Monday"
        name={[...formKeys, 'mon']}
        style={{ marginBottom: 8 }}
        validateTrigger="onChange"
        rules={[hourValidator]}
      >
        <TimePicker.RangePicker
          format={'HH:mm'}
          order={false}
          onChange={(value) => setMondayValue(value)}
          disabled={disabled}
        />
      </Form.Item>
      {mondayValue ? (
        <Button
          onClick={copySchedule}
          type="primary"
          style={{ marginTop: 4, marginBottom: 12 }}
          disabled={disabled}
        >
          Set all
        </Button>
      ) : null}
      <Form.Item
        label="Tuesday"
        name={[...formKeys, 'tue']}
        style={{ marginBottom: 8 }}
        validateTrigger="onChange"
        rules={[hourValidator]}
      >
        <TimePicker.RangePicker
          format={'HH:mm'}
          order={false}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        label="Wednesday"
        name={[...formKeys, 'wed']}
        style={{ marginBottom: 8 }}
        validateTrigger="onChange"
        rules={[hourValidator]}
      >
        <TimePicker.RangePicker
          format={'HH:mm'}
          order={false}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        label="Thursday"
        name={[...formKeys, 'thu']}
        style={{ marginBottom: 8 }}
        validateTrigger="onChange"
        rules={[hourValidator]}
      >
        <TimePicker.RangePicker
          format={'HH:mm'}
          order={false}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        label="Friday"
        name={[...formKeys, 'fri']}
        style={{ marginBottom: 8 }}
        validateTrigger="onChange"
        rules={[hourValidator]}
      >
        <TimePicker.RangePicker
          format={'HH:mm'}
          order={false}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        label="Saturday"
        name={[...formKeys, 'sat']}
        style={{ marginBottom: 8 }}
        validateTrigger="onChange"
        rules={[hourValidator]}
      >
        <TimePicker.RangePicker
          format={'HH:mm'}
          order={false}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        label="Sunday"
        name={[...formKeys, 'sun']}
        style={{ marginBottom: 8 }}
        validateTrigger="onChange"
        rules={[hourValidator]}
      >
        <TimePicker.RangePicker
          format={'HH:mm'}
          order={false}
          disabled={disabled}
        />
      </Form.Item>
    </Form.Item>
  )
}
