import { Card, Form, Checkbox, Switch } from 'antd'
import { LocationDTO } from '../../../../../../../types/interfaces'

interface Props {
  location: LocationDTO
  hasSelectedCuisine: boolean
  hasPrimaryPhoto: boolean
}

const Status = ({ location, hasSelectedCuisine, hasPrimaryPhoto }: Props) => (
  <Card size="small" title="Status" style={{ marginBottom: 16 }}>
    <Form.Item
      name={['restaurant', 'isVisible']}
      label="Visibility"
      valuePropName="checked"
      validateTrigger="onChange"
      rules={[
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (
              !value ||
              (getFieldValue(['restaurant', 'location', 'address']) &&
                getFieldValue(['restaurant', 'location', 'city']) &&
                getFieldValue(['restaurant', 'cuisines']).length &&
                hasPrimaryPhoto)
            ) {
              return Promise.resolve()
            }
            return Promise.reject(
              new Error(
                `In order to publish, restaurant must have an address, a city, a cuisine and main photo`,
              ),
            )
          },
        }),
      ]}
    >
      <Switch />
    </Form.Item>
    <Checkbox
      style={{ pointerEvents: 'none', cursor: 'auto' }}
      checked={location?.address?.length > 0}
    >
      Address
    </Checkbox>
    <Checkbox
      style={{ pointerEvents: 'none', cursor: 'auto' }}
      checked={location?.city?.length > 0}
    >
      City
    </Checkbox>
    <Checkbox
      style={{ pointerEvents: 'none', cursor: 'auto' }}
      checked={hasSelectedCuisine}
    >
      Cuisines
    </Checkbox>
    <Checkbox
      style={{ pointerEvents: 'none', cursor: 'auto' }}
      checked={hasPrimaryPhoto}
    >
      Main photo
    </Checkbox>
  </Card>
)

export default Status
