import { Route, Switch, Redirect } from 'react-router-dom'

import {
  Home,
  Other,
  Cuisines,
  Restaurants,
  Restaurant,
  Users,
  User,
  Tags,
  Tools,
} from './pages'
import InteriorDesigns from './pages/InteriorDesigns'

const Dashboard = () => {
  return (
    <Switch>
      <Route path="/dashboard">
        <Home />
      </Route>
      <Route exact path="/users">
        <Users />
      </Route>
      <Route path="/users/:id">
        <User />
      </Route>
      <Route exact path="/restaurants">
        <Restaurants />
      </Route>
      <Route path="/restaurants/:id">
        <Restaurant />
      </Route>
      <Route exact path="/tags">
        <Tags />
      </Route>
      <Route exact path="/cuisines">
        <Cuisines />
      </Route>
      <Route exact path="/interior-designs">
        <InteriorDesigns />
      </Route>
      <Route path="/other">
        <Other />
      </Route>
      <Route path="/tools">
        <Tools />
      </Route>
      <Route path="*">
        <Redirect to="/users" />
      </Route>
    </Switch>
  )
}

export default Dashboard
