import { Dispatch, SetStateAction, useState } from 'react'
import { Empty, Button, FormInstance } from 'antd'
import ItemsModal from '../modals/ItemsModal'
import LunchModal from '../modals/LunchModal'
import { MenuCard } from './Menu'
import { MenuItemList } from './MenuSection/MenuItemList'
import { MenuDTO, MenuItemDTO } from '../../../../../../../types/interfaces'
import { useMutation } from '@apollo/client'
import { DELETE_LUNCH } from '../../../../../../../clients/apollo'

interface Props {
  lunches: MenuDTO[] | undefined
  refetchLunches: () => void
  selectedItem: MenuItemDTO | undefined
  setSelectedItem: Dispatch<SetStateAction<MenuItemDTO | undefined>>
  form: FormInstance<any>
  restaurantId: string
  lunchesLoading: boolean
  setItemsModalVisible: Dispatch<SetStateAction<boolean>>
}

const Lunches = ({
  lunches,
  refetchLunches,
  selectedItem,
  setSelectedItem,
  form,
  restaurantId,
  lunchesLoading,
  setItemsModalVisible,
}: Props) => {
  const [selectedLunch, setSelectedLunch] =
    useState<MenuDTO | undefined>(undefined)
  const [isLunchModalVisible, setIsLunchModalVisible] = useState(false)

  const [deleteLunch] = useMutation(DELETE_LUNCH)

  const handleDeleteLunch = (lunchId: string) => {
    deleteLunch({ variables: { menuId: lunchId } })
    refetchLunches()
  }

  return (
    <div>
      {lunches &&
        lunches.map((lunch) => (
          <MenuCard
            key={lunch.id}
            onItemAdd={() => {
              setSelectedItem(undefined)
              form.resetFields()
              setSelectedLunch(lunch)
            }}
            activeTabKey="Lunch"
            title={`Lunch on ${lunch.date} at ${lunch.servingHours}`}
            onLunchDelete={() => handleDeleteLunch(lunch.id)}
          >
            <MenuItemList
              loading={lunchesLoading}
              items={lunch.items || []}
              setItemsModalVisible={setItemsModalVisible}
              setSelectedItem={setSelectedItem}
              form={form}
              refetchMenuItems={refetchLunches}
            />
          </MenuCard>
        ))}
      <div style={{ marginTop: 50 }}>
        <Empty description="Add new lunch">
          <Button type="primary" onClick={() => setIsLunchModalVisible(true)}>
            Add lunch
          </Button>
        </Empty>
      </div>
      <LunchModal
        isVisible={isLunchModalVisible}
        setIsVisible={setIsLunchModalVisible}
        restaurantId={restaurantId}
        refetchLunch={refetchLunches}
      />
      {selectedLunch && (
        <ItemsModal
          closeModal={() => setSelectedLunch(undefined)}
          visible={!!selectedLunch}
          menuItem={selectedItem}
          form={form}
          menu={selectedLunch}
          refetchMenu={refetchLunches}
        />
      )}
    </div>
  )
}

export default Lunches
