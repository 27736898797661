import { Button, Form, Modal } from 'antd'
import { MenuSectionDTO } from 'types/interfaces'
import { axios } from 'clients'
import { SectionsForm } from '../components/SectionsForm'
import { fetchSections, sectionsKey } from 'utils'
import { QueryObserverResult, RefetchOptions, useQuery } from 'react-query'
import { decodeWeek, encodeWeek } from '../../RestaurantDetails/helpers'

interface Props {
  setVisible: (value: boolean) => void
  visible: boolean
  sectionData: MenuSectionDTO
  menuId: string
  fetchSection: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<MenuSectionDTO, Error>>
}

export const EditSectionModal = ({
  visible,
  setVisible,
  sectionData,
  menuId,
  fetchSection,
}: Props) => {
  const [form] = Form.useForm()
  form.setFieldsValue({
    description: sectionData.description,
    availability: decodeWeek(sectionData.availability),
  })

  const { refetch: refetchSections } = useQuery<MenuSectionDTO[], Error>(
    sectionsKey({ menuId }),
    fetchSections,
    { retry: 3 },
  )

  const onOk = () => {
    setVisible(false)
  }

  const updateSection = (values: any) => {
    const availability = encodeWeek(values.availability)

    const payload: MenuSectionDTO = {
      id: values.id,
      description: values.description,
      availability,
    }

    axios
      .put(`/menu-sections/update`, payload)
      .then(({ data }) => {
        refetchSections()
        fetchSection()
        form.resetFields()
        setVisible(false)
      })
      .catch(() => console.log("Couldn't update this section..."))
  }

  return (
    <Modal
      title="Menu sections"
      centered
      okText="Close"
      onOk={onOk}
      onCancel={() => setVisible(false)}
      visible={visible}
      destroyOnClose={true}
      footer={[
        <Button
          type="primary"
          key="back"
          onClick={() => {
            setVisible(false)
            form.resetFields()
          }}
        >
          Close
        </Button>,
      ]}
    >
      <SectionsForm
        form={form}
        onFinish={(values: any) =>
          updateSection({ ...values, id: sectionData.id })
        }
        actionType="Submit"
      />
    </Modal>
  )
}

export default EditSectionModal
