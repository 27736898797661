import React, { useState } from 'react'
import { Button, Popconfirm } from 'antd'
import { useCountdown } from 'utils/hooks'

interface Props {
  title: React.ReactNode | (() => React.ReactNode)
  onConfirm: (id: number | string | null) => void
  id: number | string | null
  buttonCaption: string
  delay: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  type?: 'button' | 'link'
  style?: React.CSSProperties
}

export const DeletionConfirm = ({
  title,
  onConfirm,
  id,
  buttonCaption,
  delay,
  type = 'button',
  style,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false)

  const [timer] = useCountdown(isVisible, delay)

  const linkStyle = type === 'link' ? { padding: 0 } : undefined

  const computedStyle = {
    ...linkStyle,
    ...style,
  }

  return (
    <Popconfirm
      style={{ whiteSpace: 'pre-line' }}
      title={title}
      onConfirm={() => {
        onConfirm(id)
      }}
      okText={`Yes${timer ? ` (${timer})` : ''}`}
      cancelText="Cancel"
      okButtonProps={{ disabled: !!timer }}
      onVisibleChange={(visible: boolean) => setIsVisible(visible)}
    >
      <Button type={type === 'link' ? 'link' : 'default'} style={computedStyle}>
        {buttonCaption}
      </Button>
    </Popconfirm>
  )
}
