import {
  Availability,
  Badge,
  CuisineDTO,
  DeliveryDTO,
  Feature,
  InteriorDesignDTO,
  Provider,
  RestaurantDTO,
} from '../../../../../../types/interfaces'
import moment from 'moment'
import {
  RestaurantFormData,
  PartialDelivery,
  UpdateRestaurantPayload,
} from './types'

export const encodeWeek = (weekHours: {
  [key: string]: moment.Moment[] | undefined
}): Availability => {
  return {
    mon: weekHours.mon
      ? `${weekHours.mon[0].format('HH:mm')}-${weekHours.mon[1].format(
          'HH:mm',
        )}`
      : null,
    tue: weekHours.tue
      ? `${weekHours.tue[0].format('HH:mm')}-${weekHours.tue[1].format(
          'HH:mm',
        )}`
      : null,
    wed: weekHours.wed
      ? `${weekHours.wed[0].format('HH:mm')}-${weekHours.wed[1].format(
          'HH:mm',
        )}`
      : null,
    thu: weekHours.thu
      ? `${weekHours.thu[0].format('HH:mm')}-${weekHours.thu[1].format(
          'HH:mm',
        )}`
      : null,
    fri: weekHours.fri
      ? `${weekHours.fri[0].format('HH:mm')}-${weekHours.fri[1].format(
          'HH:mm',
        )}`
      : null,
    sat: weekHours.sat
      ? `${weekHours.sat[0].format('HH:mm')}-${weekHours.sat[1].format(
          'HH:mm',
        )}`
      : null,
    sun: weekHours.sun
      ? `${weekHours.sun[0].format('HH:mm')}-${weekHours.sun[1].format(
          'HH:mm',
        )}`
      : null,
  }
}

export const decodeWeek = (weekHours: Availability) => {
  const res: { [key: string]: moment.Moment[] | undefined } = {
    mon: undefined,
    tue: undefined,
    wed: undefined,
    thu: undefined,
    fri: undefined,
    sat: undefined,
    sun: undefined,
  }
  Object.keys(res).forEach((key) => {
    const parsedTime = weekHours[key as keyof Availability]
      ?.split(/[-:]/)
      .map((element) => parseInt(element))
    if (parsedTime) {
      res[key as keyof Availability] = [
        moment().hour(parsedTime[0]).minute(parsedTime[1]),
        moment().hour(parsedTime[2]).minute(parsedTime[3]),
      ]
    }
  })
  return res
}

export const removeNulls = (object: Record<string, any>) => {
  if (object === null) {
    return undefined
  }
  if (typeof object === 'object') {
    for (const key in object) {
      object[key] = removeNulls(object[key])
    }
  }
  return object
}

export const parseRestaurantToInitialValues = (restaurant: RestaurantDTO) => {
  const restaurantWithoutNulls = removeNulls(restaurant) as RestaurantDTO
  return {
    ...restaurantWithoutNulls,
    cuisines: restaurant?.cuisines?.map(({ name }) => name),
    mainCuisine: restaurant?.mainCuisine?.name,
    interiorDesign: restaurant?.interiorDesign?.name,
    delivery: Object.keys(restaurant.delivery) as Array<keyof DeliveryDTO>,
    ...restaurant.delivery,
    features: restaurant.features.reduce(
      (object, feature) => ({ ...object, [feature]: true }),
      {} as { [key in Feature]: boolean },
    ),
    badges: restaurant.badges.reduce(
      (object, badge) => ({ ...object, [badge]: true }),
      {} as { [key in Badge]: boolean },
    ),
    openingHours: decodeWeek(restaurant.openingHours),
    kitchenHours: decodeWeek(restaurant.kitchenHours),
  }
}

const getDeliveryForPayload = (
  restaurant: RestaurantFormData['restaurant'],
) => {
  const deliveryFiltered = restaurant.delivery
    .filter((name) => !['restaurant', 'self'].includes(name))
    .reduce(
      (object, current) => ({ ...object, [current]: restaurant[current] }),
      {} as { [key in keyof PartialDelivery]: Provider },
    )
  let delivery: DeliveryDTO = deliveryFiltered
  if (restaurant.delivery.some((deliveryOption) => deliveryOption === 'self')) {
    delivery = { ...delivery, self: true }
  }
  if (
    restaurant.delivery.some(
      (deliveryOption) => deliveryOption === 'restaurant',
    )
  ) {
    const filteredRestaurantDelivery: { restaurant?: Provider } = {}
    const shouldBeRestaurantDeliverAdded =
      restaurant.restaurant?.url ||
      (restaurant.restaurant?.number?.code &&
        restaurant.restaurant?.number?.phone)

    if (shouldBeRestaurantDeliverAdded) {
      filteredRestaurantDelivery.restaurant = {}
      if (restaurant.restaurant?.url) {
        filteredRestaurantDelivery.restaurant.url = restaurant.restaurant?.url
      }
      if (
        restaurant.restaurant?.number?.code &&
        restaurant.restaurant?.number?.phone
      ) {
        filteredRestaurantDelivery.restaurant.number =
          restaurant.restaurant?.number
      }
    }

    delivery = {
      ...delivery,
      ...filteredRestaurantDelivery,
    }
  }

  return delivery
}

const getPhoneNumberForPayload = (
  restaurant: RestaurantFormData['restaurant'],
) => {
  if (
    restaurant.phoneNumber &&
    restaurant.phoneNumber.code &&
    restaurant.phoneNumber.phone
  ) {
    return {
      code: restaurant.phoneNumber.code,
      phone: restaurant.phoneNumber.phone,
    }
  }
  return null
}

export const parseRestaurantToPayload = (
  formData: RestaurantFormData,
  allCuisines: CuisineDTO[],
  allInteriorDesigns: InteriorDesignDTO[],
): UpdateRestaurantPayload => {
  const { restaurant } = formData
  const openingHours = encodeWeek(restaurant.openingHours)
  const kitchenHours = encodeWeek(restaurant.kitchenHours)
  const delivery = getDeliveryForPayload(restaurant)
  const phoneNumber = getPhoneNumberForPayload(restaurant)

  const { features } = restaurant
  const featuresParsed = (
    Object.keys(features) as Array<keyof typeof features>
  ).filter((key) => !!features[key])

  const { badges } = restaurant
  const badgesParsed = (
    Object.keys(badges) as Array<keyof typeof badges>
  ).filter((key) => !!badges[key])

  const cuisines = restaurant.cuisines.reduce((array, cuisine) => {
    const cuisineFound = allCuisines.find(
      (cuisineObject) => cuisineObject.name === cuisine,
    )
    if (cuisineFound) {
      array.push(cuisineFound)
    }
    return array
  }, [] as CuisineDTO[])

  const mainCuisine = allCuisines.find(
    (cuisineObject) => cuisineObject.name === restaurant.mainCuisine,
  )

  const interiorDesign = allInteriorDesigns.find(
    (interiorDesignObject) =>
      interiorDesignObject.name === restaurant.interiorDesign,
  )

  return {
    ...restaurant,
    phoneNumber,
    openingHours,
    kitchenHours,
    delivery,
    features: featuresParsed,
    badges: badgesParsed,
    cuisines,
    mainCuisine,
    interiorDesign,
  }
}
// sorting functions
interface ObjectWithMeta {
  meta: {
    priority: number
  }
}

export const byPriority = (a: ObjectWithMeta, b: ObjectWithMeta) =>
  a.meta.priority - b.meta.priority
