import { useState } from 'react'
import { Divider } from 'antd'
import { useParams } from 'react-router-dom'
import {
  RestaurantDetails,
  RestaurantGallery,
  RestaurantMenu,
  RestaurantOwners,
} from './components'
import DashboardLayout from 'components/DashboardLayout'
import { useQuery } from 'react-query'
import { RestaurantDTO } from '../../../../types/interfaces'
import { restaurantKey, fetchRestaurant } from '../../../../utils'
import { Spinner } from '../../../../components/Spinner'
import { UploadFileWithPrior } from 'types/types'

const Restaurant = () => {
  const { id } = useParams<{ id: string }>()
  const [primaryPhoto, setPrimaryPhoto] = useState<UploadFileWithPrior[]>([])
  const [logoPhoto, setLogoPhoto] = useState<UploadFileWithPrior[]>([])
  const [interiorPhotos, setInteriorPhotos] = useState<UploadFileWithPrior[]>(
    [],
  )
  const [gardenPhotos, setGardenPhotos] = useState<UploadFileWithPrior[]>([])

  const { isLoading, error, data } = useQuery<RestaurantDTO, Error>(
    restaurantKey({ restaurantId: id }),
    fetchRestaurant,
    { retry: 3 },
  )

  if (isLoading) {
    return <Spinner />
  }

  if (!data || error) {
    return <div>Hey something went wrong :(</div>
  }

  return (
    <DashboardLayout header="Restaurant">
      <div>
        <RestaurantDetails
          restaurant={data}
          hasPrimaryPhoto={!!primaryPhoto.length}
        />
        <Divider />
        <RestaurantGallery
          id={id}
          primaryPhoto={primaryPhoto}
          setPrimaryPhoto={setPrimaryPhoto}
          logoPhoto={logoPhoto}
          setLogoPhoto={setLogoPhoto}
          interiorPhotos={interiorPhotos}
          setInteriorPhotos={setInteriorPhotos}
          gardenPhotos={gardenPhotos}
          setGardenPhotos={setGardenPhotos}
          isRestaurantVisible={data.isVisible}
        />
        <Divider />
        <RestaurantOwners id={id} />
        <Divider />
        <RestaurantMenu id={id} defaultAvailability={data.openingHours} />
        <div style={{ height: 400 }} />
      </div>
    </DashboardLayout>
  )
}

export default Restaurant
