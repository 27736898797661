import React from 'react'
import { Breadcrumb } from 'antd'
import {
  HomeOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  QuestionCircleOutlined,
  TagsOutlined,
  ToolOutlined,
  DashboardOutlined,
} from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import BookOutlined from '@ant-design/icons/lib/icons/BookOutlined'

const breadcrumbNameMap: Record<string, any> = {
  '/dashboard': () => (
    <>
      <DashboardOutlined style={{ marginRight: 4 }} />
      <span>Dashboard</span>
    </>
  ),
  '/restaurants': () => (
    <>
      <EnvironmentOutlined style={{ marginRight: 4 }} />
      <span>Restaurants</span>
    </>
  ),
  '/restaurants/:id': ({ id }: any) => id,
  '/users': () => (
    <>
      <TeamOutlined style={{ marginRight: 4 }} />
      <span>Users</span>
    </>
  ),
  '/users/:id': ({ id }: any) => id,
  '/tags': () => (
    <>
      <TagsOutlined style={{ marginRight: 4 }} />
      <span>Tags</span>
    </>
  ),
  '/cuisines': () => (
    <>
      <BookOutlined style={{ marginRight: 4 }} />
      <span>Cuisines</span>
    </>
  ),
  '/other': () => (
    <>
      <QuestionCircleOutlined style={{ marginRight: 4 }} />
      <span>Other</span>
    </>
  ),
  '/tools': () => (
    <>
      <ToolOutlined style={{ marginRight: 4 }} />
      <span>Tools</span>
    </>
  ),
}

const breadcrumbMatcher = (pathname: string) => {
  const paths = pathname.split('/')
  let params = null
  const crumbResolverKey = Object.keys(breadcrumbNameMap).find(
    (crumbPathname) => {
      const crumbPaths = crumbPathname.split('/')
      if (paths.length !== crumbPaths.length) {
        return false
      }

      const pathParams: Record<string, any> = {}

      const isMatch = crumbPaths.every((value, index) => {
        if (value.startsWith(':')) {
          pathParams[value.substring(1)] = paths[index]
          return true
        }

        return value === paths[index]
      })

      if (isMatch) {
        params = pathParams
      }

      return isMatch
    },
  )

  const crumbResolver = crumbResolverKey
    ? breadcrumbNameMap[crumbResolverKey]
    : '-'

  if (typeof crumbResolver === 'function') {
    return crumbResolver(params)
  }

  return crumbResolver
}

// const breadcrumbNameMap: Record<string, any> = {
//   '/restaurants': 'Restaurants',
//   '/apps/1': 'Application1',
//   '/apps/2': 'Application2',
//   '/apps/1/detail': 'Detail',
//   '/apps/2/detail': 'Detail',
// }

export const Breadcrumbs = () => {
  const location = useLocation()
  const pathSnippets = location.pathname.split('/').filter((i) => i)
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`

    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbMatcher(url)}</Link>
      </Breadcrumb.Item>
    )
  })
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined />
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems)

  return <Breadcrumb style={{ marginBottom: 16 }}>{breadcrumbItems}</Breadcrumb>
}

export default Breadcrumbs
