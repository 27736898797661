import { useEffect, useState } from 'react'

import { Avatar, Button, Card, List, Space } from 'antd'
import { axios } from 'clients'

import Trash from 'components/Trash'
import { StaffModal } from './StaffModal'
import { ENDPOINTS } from 'utils/constants'
import { ConfirmDeleteModal } from './ConfirmDeleteModal'

export interface User {
  name: string
  email: string
  id: string
  avatar: string
}

const RestaurantStaff = ({ id }: { id: string }) => {
  const [staff, setStaff] = useState<User[]>([])
  const [userId, setUserId] = useState('')
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)

  const fetchOwners = () => {
    axios.get(ENDPOINTS.restaurantUsers(id)).then(({ data }) => {
      setStaff(data)
    })
  }

  useEffect(fetchOwners, [id])

  return (
    <Card
      style={{ width: '100%' }}
      title="Owners"
      extra={
        <Space>
          <Button onClick={() => setVisibleModal(true)}>Add New Owner</Button>
        </Space>
      }
    >
      <List
        dataSource={staff}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              avatar={<Avatar src={item.avatar} />}
              title={item.name}
              description={item.email}
            />
            <div
              onClick={() => {
                setUserId(item.id)
                setVisibleConfirmModal(true)
              }}
              style={{ cursor: 'pointer' }}
            >
              <Trash />
            </div>
          </List.Item>
        )}
      />
      <StaffModal
        id={id}
        visible={visibleModal}
        setVisible={setVisibleModal}
        staff={staff}
        setStaff={setStaff}
      />
      <ConfirmDeleteModal
        id={id}
        visible={visibleConfirmModal}
        setVisible={setVisibleConfirmModal}
        userId={userId}
        setStaff={setStaff}
      />
    </Card>
  )
}
export default RestaurantStaff
