import { RuleObject } from 'antd/lib/form'
import { NamePath } from 'antd/lib/form/interface'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { FormInstance } from 'antd'
import { RestaurantFormData } from 'pages/Dashboard/pages/Restaurant/components/RestaurantDetails/types'

export const validateRestaurantNumber = ({
  getFieldValue,
}: {
  getFieldValue: (name: NamePath) => any
}) => ({
  validator(_: RuleObject, value: { code: number; phone: string }) {
    const url = getFieldValue(['restaurant', 'restaurant', 'url'])
    if (
      (value?.code &&
        value?.phone &&
        isPossiblePhoneNumber(`+${value.code}${value.phone}`)) ||
      value?.code ||
      url
    ) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(`Phone number must be valid!`))
  },
})

export const validateRestuarantUrl = ({
  getFieldValue,
}: {
  getFieldValue: (name: NamePath) => any
}) => ({
  validator(_: RuleObject, value: string) {
    const { code } = getFieldValue(['restaurant', 'restaurant', 'number']) ?? {}
    if (value || code) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(`Invalid URL!`))
  },
})

export const getRestuarantCuisinesValidator = (
  form: FormInstance<RestaurantFormData>,
) => {
  return ({ getFieldValue }: { getFieldValue: (name: NamePath) => any }) => ({
    validator() {
      const mainCuisine = getFieldValue(['restaurant', 'mainCuisine'])
      if (
        !mainCuisine ||
        form
          .getFieldValue(['restaurant', 'cuisines'])
          .some((cuisine: string) => cuisine === mainCuisine)
      ) {
        return Promise.resolve()
      }
      return Promise.reject(
        new Error(`Cannot delete cuisine which is main cuisine`),
      )
    },
  })
}
