import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'

import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react'
import styled from 'styled-components'

import ReactMapboxGl, { MapRef, Marker, NavigationControl } from 'react-map-gl'
//@ts-ignore
import Geocoder from 'react-map-gl-geocoder'
import Pin from './Pin'

import 'mapbox-gl/dist/mapbox-gl.css'
import { Position } from 'geojson'

import mapboxgl from 'mapbox-gl'

// @ts-ignore
mapboxgl.workerClass =
  // eslint-disable-next-line
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const token =
  'pk.eyJ1IjoibWF0ZHJ1IiwiYSI6ImNrbGwzZWxzMDBhdjQydm13NTFha2l4OWcifQ.rRZiZOlFT3WkoV_PGv2_KA'

const Container = styled.div`
  height: 400px;
`

const navStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px',
}

interface Props {
  className?: string
  disableDrag?: boolean
  onChange?: (coordinates: Position) => void
  position?: Position
  onAddressSelect?: (result: any) => void
  disabled?: boolean
}

type Coordinates = {
  longitude: number
  latitude: number
}

// const userPosition: Coordinates = [21.00876708752258, 52.235270427801986]
const defaultPosition: Coordinates = {
  longitude: 21.00876708752258,
  latitude: 52.235270427801986,
}

const getPosition = (position?: Position): Coordinates => {
  if (position) {
    return {
      longitude: position[0],
      latitude: position[1],
    }
  }
  return defaultPosition
}

export const LocationPicker = ({
  className,
  onChange,
  disableDrag,
  position,
  onAddressSelect,
  disabled,
}: Props) => {
  const [viewport, setViewport] = useState({
    ...getPosition(position),
    zoom: 13,
    bearing: 0,
    pitch: 0,
  })
  const [marker, setMarker] = useState(getPosition(position))

  useEffect(
    () =>
      setViewport({
        ...getPosition(position),
        zoom: 13,
        bearing: 0,
        pitch: 0,
      }),
    [position, disabled],
  )

  useEffect(() => {
    if (position) {
      setMarker(getPosition(position))
      setViewport((viewport) => {
        if (
          viewport.longitude === defaultPosition.longitude &&
          viewport.latitude === defaultPosition.latitude
        ) {
          return {
            ...viewport,
            ...getPosition(position),
          }
        }
        return viewport
      })
    }
  }, [position])

  const onMarkerDragEnd = useCallback(
    (event) => {
      setMarker({
        longitude: event.lngLat[0],
        latitude: event.lngLat[1],
      })
      if (onChange) {
        onChange(event.lngLat)
      }
    },
    [onChange],
  )

  const mapRef = useRef<MapRef | null>(null)

  return (
    <Container
      className={className}
      style={{ pointerEvents: disabled ? 'none' : 'auto' }}
    >
      <ReactMapboxGl
        {...viewport}
        ref={mapRef}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/matdru/ckllcb4h31av617qwmv8ot0zc"
        onViewportChange={setViewport}
        mapboxApiAccessToken={token}
        scrollZoom={!disabled}
      >
        {!disabled && (
          <Geocoder
            mapRef={mapRef}
            onViewportChange={setViewport}
            mapboxApiAccessToken={token}
            position="top-right"
            types={'address'}
            trackProximity
            onResult={({ result }: { result: any }) => {
              setMarker(getPosition(result.center))
              if (onChange) {
                onChange(result.center)
              }
              if (onAddressSelect) {
                onAddressSelect(result)
              }
            }}
          />
        )}

        <Marker
          longitude={marker.longitude}
          latitude={marker.latitude}
          offsetTop={-20}
          offsetLeft={-10}
          draggable={!disableDrag}
          // onDragStart={onMarkerDragStart}
          // onDrag={onMarkerDrag}
          onDragEnd={onMarkerDragEnd}
        >
          <Pin size={20} />
        </Marker>

        {!disabled && (
          <div className="nav" style={navStyle}>
            <NavigationControl />
          </div>
        )}
      </ReactMapboxGl>
      {/* <ControlPanel events={events} /> */}
    </Container>
  )
}

export default LocationPicker
