import React from 'react'
import { Alert, Button, message } from 'antd'
import { QueryObserverResult, RefetchOptions, useQuery } from 'react-query'
import {
  ENDPOINTS,
  fetchSection,
  fetchSectionItems,
  sectionItemsKey,
  sectionKey,
} from 'utils'
import { Availability, MenuItemDTO, MenuSectionDTO } from 'types/interfaces'
import axios from 'clients/axios'
import { DeletionConfirm } from 'components/DeletionConfirm'

export const parseSectionAvailability = (sectionData: MenuSectionDTO) => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  return days
    .map((day) => {
      const availability =
        sectionData.availability[day.toLowerCase() as keyof Availability]
      return availability ? `${day}: ${availability}` : ''
    })
    .join(' ')
}

interface Props {
  section: MenuSectionDTO
  setSectionsEditVisible: React.Dispatch<React.SetStateAction<boolean>>
  //   setItemsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  //   setSelectedItem: React.Dispatch<React.SetStateAction<MenuItemDTO | undefined>>
  //   form: FormInstance<any>
  menuId: string
  setSelectedSection: React.Dispatch<
    React.SetStateAction<MenuSectionDTO | null>
  >
  refetchSections: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<MenuSectionDTO[], Error>>
  menuSections: MenuSectionDTO[] | undefined
}

export const SectionInfo = ({
  section,
  setSectionsEditVisible,
  setSelectedSection,
  refetchSections,
  menuSections,
}: Props) => {
  const {
    error,
    data: menuItemsData,
    remove: removeMenuItems,
  } = useQuery<MenuItemDTO[], Error>(
    sectionItemsKey({ sectionId: section.id.toString() }),
    fetchSectionItems,
    { retry: 3 },
  )

  const { data: sectionData } = useQuery<MenuSectionDTO, Error>(
    sectionKey({ sectionId: section.id.toString() }),
    fetchSection,
    {
      retry: 3,
    },
  )

  if (error) {
    return <Alert type="error" message="Couldnt load menu items." />
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {sectionData && (
        <Alert
          message={parseSectionAvailability(sectionData)}
          style={{ marginRight: 8 }}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <DeletionConfirm
          id={section.id}
          style={{ marginBottom: 8 }}
          title={
            <>
              Are you sure you want to delete this section?
              {menuItemsData?.length ? (
                <>
                  <br />
                  Following items will be deleted as well:
                  {menuItemsData?.map((item) => (
                    <>
                      <br />- {item.title}
                    </>
                  ))}
                </>
              ) : null}
            </>
          }
          onConfirm={(id: number | string | null) =>
            axios
              .delete(ENDPOINTS.menuSectionsDelete, {
                data: { id },
              })
              .then(async () => {
                refetchSections()
                removeMenuItems()
                setSelectedSection(
                  menuSections?.filter(
                    (element) => element.id !== section.id,
                  )[0] ?? null,
                )
              })
              .catch(() => message.error("Couldn't delete this section!"))
          }
          buttonCaption="Delete section"
          delay={3}
        />
        <Button type="primary" onClick={() => setSectionsEditVisible(true)}>
          Edit section
        </Button>
      </div>
    </div>
  )
}
