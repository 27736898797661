import { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form, Input, Modal } from 'antd'
import {
  CreateInteriorDesignInput,
  InteriorDesignDTO,
  UpdateInteriorDesignInput,
} from '../../../../types/interfaces'
import { CREATE_INTERIOR_DESIGN, UPDATE_INTERIOR_DESIGN } from 'clients/apollo'
import { useMutation } from '@apollo/client'

interface Props {
  setModalState: (value: InteriorDesignDTO | null) => void
  modalState: InteriorDesignDTO | null
  setIsModalVisible: (value: boolean) => void
  isModalVisible: boolean
  refetch: () => void
}

type State = {
  name: string
}

export const EditInteriorDesignModal = ({
  modalState,
  setModalState,
  isModalVisible,
  setIsModalVisible,
  refetch,
}: Props) => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [state, setState] = useState<State>({
    name: '',
  })

  const inputRef = useRef<any>()

  const [form] = Form.useForm()

  useEffect(() => {
    setState({
      name: modalState ? modalState.name : '',
    })
    form.resetFields()
  }, [modalState, form, isModalVisible])

  const [createInteriorDesign] = useMutation(CREATE_INTERIOR_DESIGN)
  const handleCreate = () => {
    const { name } = state
    if (name.length > 0) {
      const payload: CreateInteriorDesignInput = {
        name,
      }
      setLoading(true)
      return createInteriorDesign({ variables: payload })
    }

    return Promise.reject()
  }

  const [updateInteriorDesign] = useMutation(UPDATE_INTERIOR_DESIGN)
  const handleUpdate = () => {
    const { name } = state
    if (name.length > 0 && modalState) {
      const payload: UpdateInteriorDesignInput = {
        id: modalState.id,
        name,
      }
      setLoading(true)
      return updateInteriorDesign({ variables: { input: payload } })
    }
    return Promise.reject()
  }

  const onCancel = () => {
    setIsModalVisible(false)
    setModalState(null)
  }

  const handleOk = () =>
    form
      .validateFields()
      .catch((info) => {
        setLoading(false)
        console.log('Validate Failed:', info)
      })
      .then(() => (modalState ? handleUpdate() : handleCreate()))
      .then(() => {
        setLoading(false)
        form.resetFields()
        refetch()
        if (!modalState) {
          inputRef.current.focus()
        } else {
          onCancel()
        }
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })

  return (
    <Modal
      title={modalState ? 'Update interior design' : 'Create interior design'}
      centered
      visible={isModalVisible}
      confirmLoading={isLoading}
      onOk={handleOk}
      onCancel={onCancel}
      okText={modalState ? 'Update' : 'Create'}
      destroyOnClose={true}
    >
      <Form
        {...{
          labelCol: { span: 8 },
          wrapperCol: { span: 19 },
        }}
        form={form}
        onFinish={handleOk}
      >
        <Form.Item
          label="Interior design name"
          name="name"
          rules={[
            { required: true, message: 'Interior design name required!' },
          ]}
          initialValue={state.name}
        >
          <Input
            value={state.name}
            onChange={(event) =>
              setState({ ...state, name: event.target.value })
            }
            placeholder="Interior design"
            ref={inputRef}
          ></Input>
        </Form.Item>
        <Button htmlType="submit" style={{ display: 'none' }} />
      </Form>

      {error && (
        <Alert
          message={
            modalState
              ? 'This interior design cannot be updated.'
              : 'This interior design cannot be created.'
          }
          type="error"
          showIcon
        />
      )}
    </Modal>
  )
}

export default EditInteriorDesignModal
