import React from 'react'

interface Props {
  fill?: string
}

export const Logo = ({ fill = '#7A5BD2' }: Props) => (
  <svg
    width="24"
    height="26"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3465 25.9999C22.7007 25.9999 23.7985 24.9021 23.7985 23.5479C23.7985 22.1937 22.7007 21.0959 21.3465 21.0959C19.9923 21.0959 18.8945 22.1937 18.8945 23.5479C18.8945 24.9021 19.9923 25.9999 21.3465 25.9999Z"
      fill={fill}
    />
    <path
      d="M20.1265 0.325667C19.6602 0.0293126 19.0953 -0.0697502 18.556 0.0502541C18.0166 0.170258 17.547 0.499508 17.2504 0.96563L10.5467 11.5091L3.843 0.96563C3.5463 0.499201 3.07647 0.16974 2.53685 0.0497233C1.99724 -0.0702931 1.43205 0.0289669 0.965623 0.325667C0.499194 0.622367 0.169732 1.0922 0.049716 1.63182C-0.00971006 1.89901 -0.0159278 2.17528 0.0314187 2.44488C0.0787652 2.71447 0.178748 2.9721 0.325659 3.20305L8.44657 15.9704V23.8461C8.44657 24.3989 8.66615 24.929 9.05701 25.3199C9.44787 25.7107 9.97798 25.9303 10.5307 25.9303C11.0835 25.9303 11.6136 25.7107 12.0045 25.3199C12.3953 24.929 12.6149 24.3989 12.6149 23.8461V16.0195L20.7665 3.20305C20.9134 2.9721 21.0134 2.71448 21.0608 2.44488C21.1081 2.17528 21.1019 1.899 21.0425 1.6318C20.9831 1.36461 20.8716 1.11174 20.7144 0.887627C20.5572 0.663518 20.3575 0.472563 20.1265 0.325667Z"
      fill={fill}
    />
  </svg>
)

export default Logo
