import { Alert, Button, Form, Modal } from 'antd'
import { useQuery } from 'react-query'

import {
  Availability,
  CreateSectionDTO,
  MenuSectionDTO,
} from 'types/interfaces'
import { fetchSections, sectionsKey } from 'utils/queries'
import Table, { ColumnsType } from 'antd/lib/table'
import { axios } from 'clients'
import { decodeWeek, encodeWeek } from '../../RestaurantDetails/helpers'
import { parseSectionAvailability } from '../components/MenuSection/MenuItem'
import { SectionsForm } from '../components/SectionsForm'

const columns: ColumnsType<
  Omit<MenuSectionDTO, 'availability'> & { availability: string }
> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Availability',
    dataIndex: 'availability',
    key: 'availability',
  },
]

interface Props {
  setVisible: (value: boolean) => void
  visible: boolean
  menuId: string
  defaultAvailability: Availability | undefined
}

export const SectionsModal = ({
  visible,
  setVisible,
  menuId,
  defaultAvailability,
}: Props) => {
  const [form] = Form.useForm()
  form.setFieldsValue({
    availability: defaultAvailability
      ? decodeWeek(defaultAvailability)
      : undefined,
  })

  const {
    isLoading,
    data,
    refetch: refetchSections,
  } = useQuery<MenuSectionDTO[], Error>(
    sectionsKey({ menuId }),
    fetchSections,
    { retry: 3 },
  )

  const onOk = () => {
    setVisible(false)
  }

  const addSection = (values: any) => {
    const payload: CreateSectionDTO = {
      description: values.description,
      menuId,
    }
    const availability = encodeWeek(values.availability)
    payload.availability = availability

    axios
      .post(`/menu-sections/create`, payload)
      .then(({ data }) => {
        form.resetFields()
        refetchSections()
      })
      .catch(() => console.log('Couldnt create this section...'))
  }

  return (
    <Modal
      title="Menu sections"
      centered
      okText="Close"
      onOk={onOk}
      onCancel={() => setVisible(false)}
      visible={visible}
      destroyOnClose={true}
      footer={[
        <Button type="primary" key="back" onClick={() => setVisible(false)}>
          Close
        </Button>,
      ]}
    >
      <SectionsForm form={form} onFinish={addSection} actionType="Add" />
      {data && (
        <Table
          columns={columns}
          dataSource={data.map((section) => ({
            ...section,
            availability: parseSectionAvailability(section),
          }))}
          rowKey="id"
        />
      )}
      {!data && !isLoading && (
        <Alert
          message="Error"
          description="Couldn't fetch sections for this menu."
          type="error"
          showIcon
        />
      )}
    </Modal>
  )
}

export default SectionsModal
