import { ReactNode } from 'react'
import { Card, Form, FormInstance } from 'antd'
import { Point } from 'geojson'
import LocationPicker from '../../../../../../../components/LocationPicker'
import {
  GeocoderResponse,
  LocationDTO,
} from '../../../../../../../types/interfaces'
import { RestaurantFormData } from '../types'

interface Props {
  actionButtons: ReactNode
  form: FormInstance<RestaurantFormData>
  location: LocationDTO
  isDisabled: boolean
}

const Location = ({ actionButtons, form, location, isDisabled }: Props) => {
  const setGeocoderAddress = (result: GeocoderResponse) => {
    if (result.place_type.length !== 1 || 'address' !== result.place_type[0]) {
      return
    }

    const postcode =
      location.postcode || result.place_name.match(/\d\d-\d\d\d/g)?.[0]

    const address = location.address || result.place_name.split(',')?.[0]

    const city =
      location.city ||
      result.place_name.split(',')?.[1]?.match(/[\p{Letter}]+/gu)?.[0]

    const coordinates = result.center

    const newLocation = {
      address,
      city,
      pin: {
        type: 'Point',
        coordinates: coordinates,
      } as Point,
      postcode,
    }

    form.setFieldsValue({
      restaurant: {
        location: newLocation,
      },
    })
  }

  return (
    <Card
      size="small"
      title="Location"
      style={{ marginBottom: 16 }}
      extra={actionButtons}
    >
      <Form.Item
        valuePropName="position"
        name={['restaurant', 'location', 'pin', 'coordinates']}
        wrapperCol={{ xs: 24 }}
      >
        <LocationPicker
          disabled={isDisabled}
          onAddressSelect={setGeocoderAddress}
        />
      </Form.Item>
      <Form.Item name={['restaurant', 'location', 'pin', 'type']} noStyle />
    </Card>
  )
}

export default Location
