import React, { useState } from 'react'
import { Alert, Button, Form, Input, Modal, Select } from 'antd'
import { axios } from '../../../../clients'
import { Access, CreateUserDTO, Level } from '../../../../types/interfaces'
import { ENDPOINTS } from '../../../../utils'

const { Option } = Select

interface Props {
  setVisible: (value: boolean) => void
  visible: boolean
  refetch?: () => void
  accesses: Access[]
}

type State = {
  email: string
  password: string
  accessLevel: Access
}

export const dropdownAccessesOptions = (data: Access[]) => {
  return (
    <Select.OptGroup label="Available levels:">
      {data.map((access) => (
        <Option key={access.id} value={access.id}>
          {access.level}
        </Option>
      ))}
    </Select.OptGroup>
  )
}

export const CreateUserModal = ({
  visible,
  setVisible,
  refetch,
  accesses,
}: Props) => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [state, setState] = useState<State>({
    email: '',
    password: '',
    accessLevel: { id: Level.User, level: 'User' },
  })

  const [form] = Form.useForm()

  const handleCreate = (values: any) => {
    const { email, password, accessLevel } = state
    if (email.length > 0 && password.length > 0) {
      const payload: CreateUserDTO = {
        password,
        email,
        accessLevel,
      }

      setLoading(true)

      return axios.post(ENDPOINTS.userCreate, payload)
    }

    return Promise.reject()
  }

  const onCancel = () => setVisible(false)

  const handleOk = () =>
    form
      .validateFields()
      .catch((info) => {
        setLoading(false)
        console.log('Validate Failed:', info)
      })
      .then((values) => handleCreate(values))
      .then(() => {
        setLoading(false)
        form.resetFields()
        setVisible(false)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })

  return (
    <Modal
      title="Create user"
      centered
      visible={visible}
      confirmLoading={isLoading}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Create"
      destroyOnClose={true}
    >
      <Form
        {...{
          labelCol: { span: 4 },
          wrapperCol: { span: 20 },
        }}
        form={form}
        onFinish={handleOk}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Email required!' }]}
        >
          <Input
            value={state.email}
            autoComplete="new-password"
            onChange={(e) => setState({ ...state, email: e.target.value })}
            placeholder="Email"
          ></Input>
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Password required!' }]}
        >
          <Input.Password
            value={state.password}
            autoComplete="new-password"
            onChange={(e) => setState({ ...state, password: e.target.value })}
            placeholder="Password"
          ></Input.Password>
        </Form.Item>
        <Form.Item
          label="Access level"
          name="accessLevel"
          rules={[{ required: true, message: 'Access level required!' }]}
        >
          <Select
            onChange={async (e) => {
              const { data } = await axios.get<Access>(
                `${ENDPOINTS.accesses}/${e}`,
              )
              return setState({ ...state, accessLevel: data })
            }}
          >
            {dropdownAccessesOptions(accesses)}
          </Select>
        </Form.Item>
        <Button htmlType="submit" style={{ display: 'none' }} />
      </Form>

      {error && (
        <Alert message="This user cannot be created." type="error" showIcon />
      )}
    </Modal>
  )
}

export default CreateUserModal
