import { Point } from 'geojson'
import { Amount } from './types'

type Longitude = number
type Latitude = number

export type Coordinates = [Longitude, Latitude]

export interface Availability {
  mon: string | null
  tue: string | null
  wed: string | null
  thu: string | null
  fri: string | null
  sat: string | null
  sun: string | null
}

export type AssetType = 'primary' | 'logo' | 'interior' | 'garden'

export interface AssetMeta {
  type: AssetType
  priority: number
}

export interface Asset {
  id: string
  url: string
  key: string
  meta: AssetMeta
}

// location dtos
export interface CreateLocationDTO {
  pin: Point
  address?: string
  city?: string
  postcode?: string
}

export interface LocationDTO {
  // location
  id: string
  pin: Point
  address: string
  city: string
  postcode: string
}

// restaurant dtos
export interface CreateRestaurantDTO {
  name: string
  cover?: string

  location: CreateLocationDTO
}

export interface ListedRestaurantDTO {
  id: string
  name: string
  isVisible: boolean
  location: {
    pin: Point
    address: string
    city: string
    postcode: string
  }
}

export interface BillingDTO {
  id: string
  companyName: string
  companyAddress: string
  nip: string
  email: string
}

export const validFeatures = [
  'garden',
  'vip',
  'business',
  'parking',
  'wifi',
  'pets',
  'disabled',
  'kids',
  'smoking',
  'quiet',
  'lively',
] as const

export type Feature = typeof validFeatures[number]

export const validBadges = ['design'] as const

export type Badge = typeof validBadges[number]

export interface RestaurantDTO {
  // restaurant
  id: string
  name: string
  cover: string
  isVisible: boolean
  location: LocationDTO
  assets: Asset[]
  menu: MenuDTO
  delivery: DeliveryDTO
  openingHours: Availability
  kitchenHours: Availability
  cuisines: CuisineDTO[]
  mainCuisine?: CuisineDTO
  ratingUrl?: string
  billing?: BillingDTO
  description?: string
  phoneNumber?: PhoneNumber
  webPageUrl?: string
  starRating?: number
  priceRange: number
  features: Feature[]
  badges: Badge[]
  interiorDesign?: InteriorDesignDTO
}

// user dtos
export interface CreateUserDTO {
  email: string
  password: string
  accessLevel: Access
}

// access dtos
export enum Level {
  User,
  Editor,
  Admin,
}

export interface Access {
  id: Level
  level: string
}

// tag dtos
export interface CreateTagDTO {
  name: string
}

export interface GetTagDTO {
  id: string
}

export interface TagDTO {
  id: string
  name: string
}

export interface CuisineDTO {
  id: string
  name: string
}

export interface CreateCuisineDTO {
  name: string
}

// menus
export interface MenuDTO {
  id: string
  title: string
  primaryMenuItem: MenuItemDTO
  items: MenuItemDTO[]
  date?: string
  servingHours?: string
}

export interface UpdateMenuDTO {
  id: string
  title: string
  primaryMenuItem: MenuItemDTO | null
}

export interface MenuItemDTO {
  id: string
  title: string
  description: string
  tags: TagDTO[]
  price: number
  currency: string
  amount: Amount

  menu?: MenuDTO
  section?: any
  image?: Asset
}

export interface CreateMenuItemDTO {
  title: string
  description: string
  menuId: string
  sectionId: string
  image: string | undefined
  tags: string[]
  price: number
  currency: string
  amount: Amount
}

export interface UpdateMenuItemDTO {
  id: string
  title: string
  description: string
  menuId: string
  imageId?: string
  sectionId?: string
  tagIds?: string[]
  price: number
  currency: string
  amount: Amount
}

export interface MenuSectionDTO {
  id: number
  description: string
  availability: Availability
  menu?: MenuDTO
}

export interface CreateSectionDTO {
  description: string

  availability?: Availability

  menuId: string
}

export interface PhoneNumber {
  code?: number
  phone?: string
}

export interface Provider {
  url?: string
  number?: { code?: number; phone?: string }
}

export interface DeliveryDTO {
  uber?: Provider
  glovo?: Provider
  pyszne?: Provider
  wolt?: Provider
  restaurant?: Provider
  self?: true
}

export interface GeocoderResponse {
  id: string
  type: string
  place_type: string[]
  relevance: string
  address?: string
  properties: {
    accuracy?: string
    address?: string
    category?: string
    maki?: string
    landmark?: true
    wikidata?: string
    short_code?: string
    tel?: string
  } & any // why `& any`:
  // mapbox documentation: Mapbox's geocoding data is constantly being updated and improved.
  // This means that the values of properties in the response object are not guaranteed
  // and may change within the same version of the API.
  text: string
  text_pl?: string
  place_name: string
  place_name_pl?: string
  matching_text?: string
  matching_place_name?: string
  language?: string
  center: [number, number]
  context: any[]
  geometry: Point
}

export interface InteriorDesignDTO {
  id: string
  name: string
}

export type UpdateInteriorDesignInput = InteriorDesignDTO

export interface CreateInteriorDesignInput {
  name: string
}
