import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tooltip,
  Upload,
  Image,
  FormInstance,
  InputNumber,
  Switch,
} from 'antd'
import { useQuery, useQueryClient } from 'react-query'

import {
  CreateMenuItemDTO,
  MenuItemDTO,
  MenuSectionDTO,
  TagDTO,
  Asset,
  UpdateMenuItemDTO,
  MenuDTO,
  UpdateMenuDTO,
} from 'types/interfaces'
// import SortableTable from 'components/SortableTable/SortableTable'
import { fetchSections, sectionsKey } from 'utils/queries'
import { axios } from 'clients'
import { ENDPOINTS } from 'utils'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import { LabeledValue } from 'antd/lib/select'

interface Props {
  closeModal: () => void
  visible: boolean
  menuItem?: MenuItemDTO
  form: FormInstance<any>
  // menu props
  menu: MenuDTO
  refetchMenu: () => void
}

export const ItemsModal = ({
  visible,
  closeModal,
  menuItem,
  form,
  menu,
  refetchMenu,
}: Props) => {
  const [isSaving, setSaving] = useState(false)
  const queryClient = useQueryClient()
  const [isImageHoveredOver, setIsImageHoveredOver] = useState<boolean>(false)
  const [isImageZoomedIn, setIsImageZoomedIn] = useState<boolean>(false)
  const [fileToUpload, setFileToUpload] =
    useState<File | Blob | undefined>(undefined) //used for upload
  const [imagePreview, setImagePreview] =
    useState<string | undefined>(undefined) //used for displaying
  const [defaultSection, setDefaultSection] = useState<LabeledValue>()
  const [defaultTags, setDefaultTags] = useState<LabeledValue[]>([])

  useEffect(() => {
    if (menuItem) {
      const fields = form.getFieldsValue()
      fields.title = menuItem.title
      fields.description = menuItem.description
      fields.price = menuItem.price
      fields.currency = menuItem.currency
      fields.quantity = menuItem.amount.quantity
      fields.unit = menuItem.amount.unit
      fields.isPrimary = menu?.primaryMenuItem?.id === menuItem.id
      form.setFieldsValue(fields)
      if (menuItem.section) {
        setDefaultSection({
          value: menuItem.section.id,
          label: menuItem.section.description,
        })
      }
      setDefaultTags(
        menuItem.tags.map((tag) => ({
          value: tag.id,
          label: tag.name,
        })),
      )
      setImagePreview(menuItem.image?.url)
      setFileToUpload(undefined)
    }
  }, [menuItem, form, menu?.primaryMenuItem?.id])

  const reader = new FileReader()
  reader.addEventListener(
    'load',
    function () {
      setImagePreview(reader.result as string)
    },
    false,
  )
  const { data: menuSections, refetch: refetchSections } = useQuery<
    MenuSectionDTO[],
    Error
  >(sectionsKey({ menuId: menu.id }), fetchSections, { retry: 3 })

  const createItem = (payload: CreateMenuItemDTO) => {
    return axios.post<MenuItemDTO>(ENDPOINTS.menuItemsCreate, payload)
  }
  const updateItem = (payload: UpdateMenuItemDTO) => {
    return axios.put<MenuItemDTO>(ENDPOINTS.menuItemsUpdate, payload)
  }
  const updateMenu = (payload: UpdateMenuDTO) => {
    return axios.put<MenuDTO>(ENDPOINTS.menuUpdate, payload)
  }

  const handleOk = async () => {
    let asset = null
    let formData = null

    try {
      formData = await form.validateFields()
    } catch (e) {
      console.log('validation failed, early exit...')
      return
    }

    if (fileToUpload) {
      const imageFormData = new FormData()
      imageFormData.append('file', fileToUpload)
      asset = (
        await axios.post<Asset>(
          ENDPOINTS.assetUpload('primary', null),
          imageFormData,
        )
      ).data
    }

    try {
      // TODO form typescript shape
      const payload = {
        menuId: menu.id,
        sectionId: formData?.sectionId?.value,
        description: formData.description,
        title: formData.title,
        tags: formData.tags.map((tag: LabeledValue) => tag.value),
        // no picture -> undefined, picture has been selected -> asset.id
        // picture from DB remains unchanged -> menuItem.image.id
        image: asset?.id || (imagePreview ? menuItem?.image?.id : undefined),
        price: formData.price,
        currency: formData.currency,
        amount: {
          unit: formData.unit || null,
          quantity: formData.quantity || null,
        },
      }

      setSaving(true)
      let newMenuItem = null
      if (menuItem) {
        const menuItemResponse = await updateItem({
          id: menuItem.id,
          menuId: payload.menuId,
          sectionId: payload?.sectionId,
          description: payload.description,
          title: payload.title,
          tagIds: payload.tags,
          imageId: payload.image,
          price: payload.price,
          currency: payload.currency,
          amount: payload.amount,
        })
        newMenuItem = menuItemResponse.data
      } else {
        const menuItemResponse = await createItem(payload)
        newMenuItem = menuItemResponse.data
      }

      if (menu.primaryMenuItem) {
        if (menu.primaryMenuItem.id === menuItem?.id && !formData.isPrimary) {
          updateMenu({ ...menu, primaryMenuItem: null })
        }
      } else {
        if (formData.isPrimary) {
          updateMenu({
            ...menu,
            primaryMenuItem: newMenuItem,
          })
        }
      }

      setDefaultSection(undefined)
      setDefaultTags([])
      form.resetFields()
      queryClient.invalidateQueries('section')
      refetchSections()
      refetchMenu()
      closeModal()
      setSaving(false)
      setFileToUpload(undefined)
      setImagePreview(undefined)
    } catch (e) {
      console.log(e)
      setSaving(false)
      message.error('Failed to save this dish')
    }
  }

  const sectionOptions = menuSections || []

  const [tags, setTags] = useState<TagDTO[]>([])
  const fetchTags = async (partialTagName: string) => {
    axios
      .get<TagDTO[]>(`${ENDPOINTS.tagsSearch}/${partialTagName}`)
      .then((response) => setTags(response.data))
      .catch(() => setTags([]))
  }

  return (
    <Modal
      title={menuItem ? 'Edit dish' : 'New dish'}
      centered
      okText={menuItem ? 'Submit' : 'Add'}
      onOk={() => handleOk()}
      onCancel={() => {
        setDefaultTags([])
        setDefaultSection(undefined)
        form.resetFields()
        closeModal()
        setFileToUpload(undefined)
        setImagePreview(undefined)
      }}
      confirmLoading={isSaving}
      visible={visible}
      destroyOnClose={true}
      width={640}
    >
      <Form form={form} onFinish={() => handleOk()} layout="vertical">
        <Row gutter={16} wrap={false} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Name is required.',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[
                { type: 'string' },
                {
                  max: 512,
                  message:
                    "Item's description cannot be longer than 256 characters",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>

            {(!menuItem || menuItem.section) && (
              <Form.Item
                label="Section"
                name="sectionId"
                rules={[
                  {
                    required: true,
                    message: 'Please choose section!',
                  },
                ]}
                initialValue={defaultSection}
              >
                <Select labelInValue>
                  {sectionOptions.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label="Tags" name="tags" initialValue={defaultTags}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Tags"
                filterOption={false}
                onSearch={(e) => {
                  fetchTags(e)
                }}
                options={tags.map((tag) => ({
                  value: tag.id,
                  label: tag.name,
                }))}
                labelInValue
              />
            </Form.Item>
            <Form.Item
              name={['isPrimary']}
              label="Primary dish"
              valuePropName="checked"
            >
              <Switch
                disabled={
                  menu?.primaryMenuItem?.id !== menuItem?.id &&
                  !!menu?.primaryMenuItem
                }
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Image">
              <ImgCrop rotate aspect={16 / 9}>
                <Upload
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      // @ts-ignore
                      onSuccess('ok')
                    }, 0)
                  }}
                  maxCount={1}
                  accept="image/*"
                  onChange={(uploadInfo) => {
                    if (uploadInfo.file.status === 'done') {
                      setFileToUpload(uploadInfo.file.originFileObj)
                      reader.readAsDataURL(
                        uploadInfo.file.originFileObj as Blob,
                      )
                    }
                  }}
                  listType="picture-card"
                  fileList={[]}
                  disabled={isImageHoveredOver || isImageZoomedIn}
                >
                  {imagePreview ? (
                    <div>
                      <Image
                        fallback="logo"
                        style={{ padding: '6px 6px 0px 6px' }}
                        src={imagePreview}
                        onMouseEnter={() => setIsImageHoveredOver(true)}
                        onMouseLeave={() => setIsImageHoveredOver(false)}
                        onClick={() => setIsImageZoomedIn(true)}
                        onPreviewClose={() => setIsImageZoomedIn(false)}
                      />
                      <Tooltip title="Delete">
                        <Button
                          shape="circle"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            setFileToUpload(undefined)
                            setImagePreview(undefined)
                            setIsImageHoveredOver(false)
                          }}
                          onMouseEnter={() => setIsImageHoveredOver(true)}
                          onMouseLeave={() => setIsImageHoveredOver(false)}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 4 }}>Upload image</div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </Form.Item>

            <div style={{ display: 'flex', marginTop: 52, gap: 12 }}>
              <Form.Item
                label="Price"
                name="price"
                rules={[
                  {
                    required: true,
                    message: 'Price is required.',
                  },
                  {
                    pattern: /^\d+(?:.\d{1,2})?$/,
                    message: 'Enter a valid price',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: 'Currency is required.',
                  },
                ]}
                initialValue="PLN"
              >
                <Select style={{ width: 128 }}>
                  <Select.Option value="PLN">PLN</Select.Option>
                  <Select.Option value="EUR">EUR</Select.Option>
                  <Select.Option value="GBP">GBP</Select.Option>
                  <Select.Option value="USD">USD</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div style={{ display: 'flex', gap: 12 }}>
              <Form.Item
                label="Quantity"
                name="quantity"
                rules={[{ type: 'number' }]}
              >
                <InputNumber style={{ width: 148 }} />
              </Form.Item>
              <Form.Item
                label="Unit"
                name="unit"
                rules={[
                  { type: 'string' },
                  {
                    max: 32,
                    message: 'Unit cannot be longer than 32 characters',
                  },
                ]}
              >
                <Input style={{ width: 128 }} />
              </Form.Item>
            </div>
          </Col>
          <Button htmlType="submit" style={{ display: 'none' }} />
        </Row>
      </Form>
    </Modal>
  )
}

export default ItemsModal
