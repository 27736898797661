import React, { useEffect } from 'react'
import { Card, Col, message, Row, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { UploadFile } from 'antd/lib/upload/interface'
import Cookies from 'js-cookie'
import { useQuery } from 'react-query'

import { ENDPOINTS, fetchRestaurant, restaurantKey } from 'utils'
import axios from 'clients/axios'
import { apiURL, IS_DEV } from 'clients/constants'
import { AssetType, RestaurantDTO } from 'types/interfaces'
import { UploadFileWithPrior } from 'types/types'

import { byPriority } from '../RestaurantDetails/helpers'

interface Props {
  id: string
  primaryPhoto: UploadFileWithPrior[]
  setPrimaryPhoto: React.Dispatch<React.SetStateAction<UploadFileWithPrior[]>>
  logoPhoto: UploadFileWithPrior[]
  setLogoPhoto: React.Dispatch<React.SetStateAction<UploadFileWithPrior[]>>
  interiorPhotos: UploadFileWithPrior[]
  setInteriorPhotos: React.Dispatch<React.SetStateAction<UploadFileWithPrior[]>>
  gardenPhotos: UploadFileWithPrior[]
  setGardenPhotos: React.Dispatch<React.SetStateAction<UploadFileWithPrior[]>>
  isRestaurantVisible: boolean
}

const RestaurantGallery = ({
  id,
  primaryPhoto,
  setPrimaryPhoto,
  logoPhoto,
  setLogoPhoto,
  interiorPhotos,
  setInteriorPhotos,
  gardenPhotos,
  setGardenPhotos,
  isRestaurantVisible,
}: Props) => {
  const { data } = useQuery<RestaurantDTO, Error>(
    restaurantKey({ restaurantId: id }),
    fetchRestaurant,
    { retry: 3 },
  )

  useEffect(() => {
    if (data) {
      const primaryAsset = data.assets.find(
        (asset) => asset.meta.type === 'primary',
      )
      const logoAsset = data.assets.find((asset) => asset.meta.type === 'logo')
      const interiorAssets = data.assets
        .filter((asset) => asset.meta.type === 'interior')
        .sort(byPriority)
      const gardenAssets = data.assets
        .filter((asset) => asset.meta.type === 'garden')
        .sort(byPriority)
      if (primaryAsset) {
        // TODO this works but TS is unhappy :(
        setPrimaryPhoto([
          // @ts-ignore
          {
            uid: primaryAsset.id,
            url: primaryAsset.url,
            name: primaryAsset.key,
            prior: primaryAsset.meta.priority,
          },
        ])
      }
      if (logoAsset) {
        setLogoPhoto([
          // @ts-ignore
          {
            uid: logoAsset.id,
            url: logoAsset.url,
            name: logoAsset.key,
            prior: logoAsset.meta.priority,
          },
        ])
      }
      setInteriorPhotos(
        // @ts-ignore
        interiorAssets.map((asset) => ({
          uid: asset.id,
          url: asset.url,
          name: asset.key,
          prior: asset.meta.priority,
        })),
      )
      setGardenPhotos(
        // @ts-ignore
        gardenAssets.map((asset) => ({
          uid: asset.id,
          url: asset.url,
          name: asset.key,
          prior: asset.meta.priority,
        })),
      )
    }
  }, [data, setPrimaryPhoto, setLogoPhoto, setInteriorPhotos, setGardenPhotos])

  const onChangeSinglePhoto = (
    fileListState: UploadFileWithPrior[],
    file: UploadFile<any>,
    setter: React.Dispatch<React.SetStateAction<UploadFileWithPrior[]>>,
  ) => {
    if (!fileListState.length) {
      setter([{ ...file, prior: 0 }])
    } else {
      setter([])
    }
  }

  const onChangeManyPhotos = (
    fileListState: UploadFileWithPrior[],
    file: UploadFile<any>,
    setter: React.Dispatch<React.SetStateAction<UploadFileWithPrior[]>>,
  ) => {
    switch (file.status) {
      case 'done':
        setter(
          fileListState
            .concat([
              // @ts-ignore
              {
                uid: file.response.id,
                url: file.response.url,
                name: file.response.key,
                prior: file.response.meta.priority,
              },
            ])
            .sort((a, b) => a.prior - b.prior),
        )
        break
      case 'removed':
        setter(fileListState.filter((photo) => photo.uid !== file.uid))
        break
    }
  }

  const onRemove = async (file: UploadFile<any>) => {
    return axios
      .delete(`restaurants/${id}/assets/${file.uid}/remove`)
      .then(({ data }) => data)
  }

  const onPreview = async (file: UploadFile<any>) => {
    let src = file.url
    const { originFileObj } = file
    if (!file.url && originFileObj) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(originFileObj)
        reader.onload = () =>
          reader.result && typeof reader.result === 'string'
            ? resolve(reader.result)
            : resolve(undefined)
      })
    }

    if (src) {
      const image = new Image()
      image.src = src
      const imgWindow = window.open(src)
      imgWindow?.document.write(image.outerHTML)
    }
  }

  const getPriority = (fileListState: UploadFileWithPrior[]) =>
    Math.min(
      ...[0, 1, 2, 3].filter(
        (prior) => !fileListState.some((file) => file.prior === prior),
      ),
    )

  const uploadURL = (type: AssetType, fileListState: UploadFileWithPrior[]) => {
    const priority = ['primary', 'logo'].includes(type)
      ? 0
      : getPriority(fileListState)

    return `${apiURL}${ENDPOINTS.restaurantPictureUpload(id)}/${type}${
      priority !== undefined ? `/${priority}` : ''
    }`
  }

  const headers: any = {}

  if (IS_DEV) {
    // in dev we will include header because of different ports
    const token = Cookies.get('ff_session')
    headers.Authorization = token ? `Bearer ${token}` : ''
  }

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} md={11}>
          <Card title="Primary">
            <ImgCrop rotate aspect={16 / 9}>
              <Upload
                withCredentials
                accept="image/*"
                action={uploadURL('primary', primaryPhoto)}
                headers={headers}
                listType="picture-card"
                fileList={primaryPhoto}
                onChange={({ file }) =>
                  onChangeSinglePhoto(primaryPhoto, file, setPrimaryPhoto)
                }
                onPreview={onPreview}
                onRemove={(file) => {
                  if (isRestaurantVisible) {
                    message.error('Hide the restaurant first!')
                    return false
                  }
                  onRemove(file)
                }}
                maxCount={1}
              >
                {!primaryPhoto.length && '+ Upload'}
              </Upload>
            </ImgCrop>
          </Card>
        </Col>
        <Col xs={24} md={13}>
          <Card title="Logo">
            <ImgCrop rotate aspect={1}>
              <Upload
                withCredentials
                accept="image/*"
                action={uploadURL('logo', logoPhoto)}
                headers={headers}
                listType="picture-card"
                fileList={logoPhoto}
                onChange={({ file }) =>
                  onChangeSinglePhoto(logoPhoto, file, setLogoPhoto)
                }
                onPreview={onPreview}
                onRemove={(file) => onRemove(file)}
                maxCount={1}
              >
                {!logoPhoto.length && '+ Upload'}
              </Upload>
            </ImgCrop>
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={11}>
          <Card title="Interior" style={{ margin: '20px 0px' }}>
            <ImgCrop rotate aspect={16 / 9}>
              <Upload
                withCredentials
                accept="image/*"
                action={uploadURL('interior', interiorPhotos)}
                headers={headers}
                listType="picture-card"
                fileList={interiorPhotos}
                onChange={({ file }) => {
                  // console.log(interiorPhotos)
                  onChangeManyPhotos(interiorPhotos, file, setInteriorPhotos)
                }}
                onPreview={onPreview}
                onRemove={onRemove}
              >
                {interiorPhotos.length < 4 && '+ Upload'}
              </Upload>
            </ImgCrop>
          </Card>
        </Col>
        <Col xs={24} md={13}>
          <Card title="Garden" style={{ margin: '20px 0px' }}>
            <ImgCrop rotate aspect={16 / 9}>
              <Upload
                withCredentials
                accept="image/*"
                action={uploadURL('garden', gardenPhotos)}
                headers={headers}
                listType="picture-card"
                fileList={gardenPhotos}
                onChange={({ file }) =>
                  onChangeManyPhotos(gardenPhotos, file, setGardenPhotos)
                }
                onPreview={onPreview}
                onRemove={onRemove}
              >
                {gardenPhotos.length < 4 && '+ Upload'}
              </Upload>
            </ImgCrop>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default RestaurantGallery
