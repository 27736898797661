import { useEffect, useState } from 'react'
import { Form, message, Modal, Row, Select } from 'antd'
import { axios } from 'clients'

import { ENDPOINTS } from 'utils/constants'
import { User } from './RestaurantOwners'

interface Props {
  id: string
  setVisible: (value: boolean) => void
  setStaff: (value: User[]) => void
  visible: boolean
  staff: User[]
}

export const StaffModal = ({
  id,
  visible,
  setVisible,
  setStaff,
  staff,
}: Props) => {
  const [availableUsers, setAvailableUsers] = useState<User[]>([])
  const [form] = Form.useForm()

  useEffect(() => {
    try {
      axios.get(ENDPOINTS.users).then(({ data }) => {
        setAvailableUsers(data)
      })
    } catch (e) {
      message.error(`Couldn't get available users`)
    }
  }, [staff])

  const handleOk = async () => {
    const userValue = form.getFieldValue('user').value
    const ownerExists = !!staff.find((user) => user.id === userValue)
    if (ownerExists) {
      message.error('User is already added to this restaurant!')
      return
    } else {
      try {
        await axios
          .post(ENDPOINTS.restaurantAddUser(id, userValue), {
            id,
            userValue,
          })
          .then(() =>
            axios.get(ENDPOINTS.restaurantUsers(id)).then(({ data }) => {
              setStaff(data)
            }),
          )

        message.success('User was added to restaurant!')
      } catch (e) {
        message.error("Couldn't add user to restaurant.")
      }
    }
  }

  return (
    <Modal
      title={'Add user to restaurant'}
      centered
      okText={'Add'}
      visible={visible}
      destroyOnClose={true}
      onOk={() => {
        handleOk()
        setVisible(false)
        form.resetFields()
      }}
      onCancel={() => {
        form.resetFields()
        setVisible(false)
      }}
      width={640}
    >
      <Form form={form} onFinish={handleOk} layout="vertical">
        <Row gutter={16} wrap={false} style={{ marginBottom: 16 }}>
          <Form.Item
            label="Select user"
            name="user"
            rules={[
              {
                required: true,
                message: 'Please select user!',
              },
            ]}
            style={{ width: '100%' }}
          >
            <Select labelInValue>
              {availableUsers.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.email}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  )
}
