import { ReactNode } from 'react'
import { Card, Form, Input } from 'antd'

interface Props {
  actionButtons: ReactNode
  isDisabled: boolean
}

const Billing = ({ actionButtons, isDisabled }: Props) => (
  <Card
    size="small"
    title="Billing"
    style={{ marginBottom: 16 }}
    extra={actionButtons}
  >
    <Form.Item
      name={['restaurant', 'billing', 'companyName']}
      label="Company's name"
      normalize={(value) => (value !== '' ? value : null)}
      rules={[
        { type: 'string' },
        {
          max: 256,
          message: "Company's name cannot be longer than 256 characters",
        },
      ]}
    >
      <Input disabled={isDisabled} />
    </Form.Item>
    <Form.Item
      name={['restaurant', 'billing', 'companyAddress']}
      label="Company's address"
      normalize={(value) => (value !== '' ? value : null)}
      rules={[
        { type: 'string' },
        {
          max: 256,
          message: "Company's address cannot be longer than 256 characters",
        },
      ]}
    >
      <Input disabled={isDisabled} />
    </Form.Item>
    <Form.Item
      name={['restaurant', 'billing', 'email']}
      label="Company's email"
      normalize={(value) => (value !== '' ? value : null)}
      rules={[
        { type: 'string' },
        {
          max: 256,
          message: "Company's email cannot be longer than 256 characters",
        },
      ]}
    >
      <Input disabled={isDisabled} />
    </Form.Item>
    <Form.Item
      name={['restaurant', 'billing', 'nip']}
      label="NIP"
      normalize={(value) => (value !== '' ? value : null)}
      rules={[
        {
          pattern: /^[0-9]*$/,
          message: 'NIP must consist only of numbers',
        },
      ]}
    >
      <Input disabled={isDisabled} />
    </Form.Item>
  </Card>
)

export default Billing
