import Cookies from 'js-cookie'

import {
  ApolloClient,
  InMemoryCache,
  gql,
  createHttpLink,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { apiURL } from './constants'

const httpLink = createHttpLink({
  uri: `${apiURL}/graphql`,
})

const isAuthRoute = (location: Location) =>
  location.pathname.includes('login') ||
  location.pathname.includes('passwordReset')

const errorLink = onError(({ graphQLErrors }) => {
  const isUnauthenticated = graphQLErrors?.some(
    (error) =>
      error.message === 'GqlAuthGuard' &&
      error?.extensions?.code === 'UNAUTHENTICATED',
  )

  if (graphQLErrors && isUnauthenticated && !isAuthRoute(window.location)) {
    window.location.replace('/login')
  }
})

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('ff_session')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const link = ApolloLink.from([authLink, errorLink, httpLink])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})

export const RESTAURANTS = gql`
  query getRestaurants($search: String, $visibilityFilter: String!) {
    restaurantsAll(searchQuery: $search, visibility: $visibilityFilter) {
      id
      name
      isVisible
      location {
        address
        city
        pin {
          coordinates
        }
      }
    }
  }
`

export const TAGS = gql`
  query getTags($search: String) {
    tags(searchQuery: $search) {
      id
      name
    }
  }
`

export const CUISINES = gql`
  query getCuisines($search: String) {
    cuisine(searchQuery: $search) {
      id
      name
    }
  }
`

export const UPDATE_CUISINE = gql`
  mutation updateCuisine($input: CuisineInput!) {
    updateCuisine(input: $input) {
      id
      name
    }
  }
`

export const UPDATE_TAG = gql`
  mutation updateTag($input: TagInput!) {
    updateTag(input: $input) {
      id
      name
    }
  }
`

export const DELETE_TAG = gql`
  mutation deleteTag($input: GetTagInput!) {
    deleteTag(input: $input)
  }
`

export const INTERIOR_DESIGNS = gql`
  query getInteriorDesigns($search: String) {
    interiorDesigns(searchQuery: $search) {
      id
      name
    }
  }
`

export const CREATE_INTERIOR_DESIGN = gql`
  mutation createInteriorDesign($name: String!) {
    createInteriorDesign(name: $name) {
      id
      name
    }
  }
`

export const UPDATE_INTERIOR_DESIGN = gql`
  mutation updateInteriorDesign($input: UpdateInteriorDesignInput!) {
    updateInteriorDesign(input: $input) {
      id
      name
    }
  }
`

export const CREATE_LUNCH = gql`
  mutation createLunch($lunchInput: LunchInput!) {
    createLunch(input: $lunchInput) {
      id
    }
  }
`

export const GET_LUNCHES = gql`
  query lunchesByRestaurant($restaurantId: String!) {
    lunchesByRestaurant(restaurantId: $restaurantId) {
      id
      title
      type
      servingHours
      date
      items {
        id
        title
        description
        image {
          url
        }
        price
        amount
        currency
        tags {
          id
          name
        }
      }
    }
  }
`

export const DELETE_LUNCH = gql`
  mutation deleteLunch($menuId: String!) {
    deleteLunch(menuId: $menuId) {
      id
    }
  }
`

export default client
