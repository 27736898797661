import { useEffect, useState } from 'react'
import { Button, Divider, List, message, Select } from 'antd'
import Title from 'antd/lib/typography/Title'
import DashboardLayout from 'components/DashboardLayout'
import { QueryFunction, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { axios } from '../../../../clients'
import { Access, Level } from '../../../../types/interfaces'
import { ENDPOINTS } from '../../../../utils'
import { dropdownAccessesOptions } from '../Users/CreateUserModal'
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Spinner } from 'components/Spinner'

// this DTO should match one in nest
interface UserDTO {
  id: string
  email: string
  name: string
  avatar: string
  accessLevel: Access
  isActive: boolean
}

type Params = {
  queryKey: [string, { id: number }]
}

const fetchUser: QueryFunction<UserDTO> = async (params: Params) => {
  const [, { id }] = params.queryKey
  const { data } = await axios.get<UserDTO>(`${ENDPOINTS.users}/${id}`)

  return data
}

const fetchAccesses: QueryFunction<Access[]> = async () => {
  const { data } = await axios.get<Access[]>(ENDPOINTS.accesses)
  return data
}

const User = () => {
  // TODO fetch user here

  const { id } = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  // const queryClient = useQueryClient()
  const {
    isLoading: isLoadingUser,
    error: errorUsers,
    data,
  } = useQuery<UserDTO, Error>(['user', { id }], fetchUser, { retry: 3 })

  const {
    isLoading: isLoadingAccesses,
    error: errorAccesses,
    data: accesses,
  } = useQuery<Access[], Error>([ENDPOINTS.accesses], fetchAccesses, {
    retry: 3,
  })

  const [accessChoice, setAccessChoice] = useState<Level | null>(null)
  useEffect(() => {
    if (data?.accessLevel) {
      setAccessChoice(data.accessLevel.id)
    }
  }, [data?.accessLevel])

  if (!data || !accesses) {
    return <div>loading...</div>
  }

  const handleAccessChange = () => {
    if (accessChoice === null) {
      message.error('No access has been chosen!')
      return
    }
    axios
      .put(ENDPOINTS.userUpdateAccess(data.id), { id: accessChoice })
      .then(() => message.success('Access succesfully updated!'))
      .catch(() => message.error('Couldnt reset access...'))
  }

  const handlePasswordReset = () => {
    axios
      .post('auth/admin-password-reset-link', { email: data.email })
      .then(() => message.success('Password reset link sent!'))
      .catch(() => message.error('Couldnt reset password...w'))
  }

  const handleActiveChange = async (e: CheckboxChangeEvent) => {
    try {
      const enable = e.target.checked
      if (enable) {
        await axios.put(ENDPOINTS.userEnable(data.id))
      } else {
        await axios.put(ENDPOINTS.userDisable(data.id))
      }
      await queryClient.invalidateQueries({ queryKey: ['user'] })
      message.success('User updated')
    } catch {
      message.error('Error updating user')
    }
  }

  if (isLoadingAccesses || isLoadingUser) {
    return <Spinner />
  }

  return (
    <DashboardLayout header="User">
      <div>
        <Title level={3}>Details</Title>
        <div>{`ID: ${data.id}`}</div>
        <div>{`Name: ${data.name}`}</div>
        <div>{`Email: ${data.email}`}</div>
        <div>{`avatar url: ${data.avatar}`}</div>
        <div>{`Active: ${data.isActive}`}</div>
        <div>{`Access level: ${data.accessLevel?.level ?? 'default'}`}</div>
        <Divider />
        <Title level={3}>Password reset</Title>
        <Button onClick={handlePasswordReset}>Reset</Button>
        <Divider />
        <Title level={3}>Change access level</Title>
        <Select
          placeholder={data.accessLevel.level}
          onChange={(value) => setAccessChoice(+value)}
          style={{ width: 90 }}
        >
          {dropdownAccessesOptions(accesses)}
        </Select>
        <Button onClick={handleAccessChange}>Change permissions</Button>
        <Divider />
        <Title level={3}>Status</Title>
        <Checkbox
          onChange={handleActiveChange}
          checked={data.isActive}
          value={data.isActive}
        >
          {data.isActive ? 'Active' : 'Inactive'}
        </Checkbox>
      </div>
    </DashboardLayout>
  )
}

export default User
