import axios from 'axios'
import Cookies from 'js-cookie'
import { apiURL, IS_DEV, IS_PREVIEW } from './constants'

const client = axios.create({
  baseURL: apiURL,
})

client.interceptors.request.use((request) => {
  request.withCredentials = true
  if (IS_DEV || IS_PREVIEW) {
    // in dev we will include header because of different ports

    const token = Cookies.get('ff_session')
    request.headers.Authorization = token ? `Bearer ${token}` : ''

    return request
  }

  return request
})

const isAuthRoute = (location: Location) =>
  location.pathname.includes('login') ||
  location.pathname.includes('passwordReset')

client.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401 && !isAuthRoute(window.location)) {
        window.location.replace('/login')
      }
    }
    return Promise.reject(error)
  },
)

export default client
