import { Form, Switch } from 'antd'

interface Props {
  formKeys: string[]
  label: string
  mapping: {
    [key: string]: string
  }
  disabled?: boolean
}

export const SwitchGroup = ({ formKeys, label, mapping, disabled }: Props) => {
  return (
    <Form.Item label={label} name={formKeys}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
        {Object.entries(mapping).map(([buttonValue, buttonLabel]) => (
          <Form.Item
            label={buttonLabel}
            key={buttonValue}
            name={[...formKeys, buttonValue]}
            valuePropName="checked"
          >
            <Switch disabled={disabled} />
          </Form.Item>
        ))}
      </div>
    </Form.Item>
  )
}
