import { useEffect, useState } from 'react'

type Delay = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export const useCountdown = (isCountingDown: boolean, delay: Delay) => {
  const [timer, setTimer] = useState(delay)
  useEffect(() => {
    if (!delay) {
      return
    }
    let intervalId: NodeJS.Timeout | null = null

    if (isCountingDown) {
      setTimer(delay)
      intervalId = setInterval(() => {
        setTimer((value) => {
          if (value) {
            return (value - 1) as Delay // any other value is not possible
          } else {
            if (intervalId) {
              clearInterval(intervalId)
            }
            return 0
          }
        })
      }, 1000)
    } else if (intervalId) {
      clearInterval(intervalId)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isCountingDown, setTimer, delay])
  return [timer]
}
