import React from 'react'
import { Button, Col, Dropdown, Layout, Menu, Row, Typography } from 'antd'
import styled from 'styled-components'
import { useLocation, Link, useHistory } from 'react-router-dom'
import {
  EnvironmentOutlined,
  TeamOutlined,
  DashboardOutlined,
  QuestionCircleOutlined,
  PoweroffOutlined,
  TagsOutlined,
  BookOutlined,
  ToolOutlined,
  HomeOutlined,
} from '@ant-design/icons'

import './DashboardLayout.css'
import Logo from './Logo'
import Breadcrumbs from 'components/Breadcrumbs'
import { axios } from 'clients'
import Cookies from 'js-cookie'
import { ENDPOINTS } from 'utils'

const { Title } = Typography

const LogoWrapper = styled.div`
  height: 32px;
  margin: 16px;
  color: white;
  display: flex;
  align-items: center;

  padding-left: 6px;
`

const Label = styled.span`
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  margin-left: 8px;
  margin-top: 6px;
`

const { Header, Content, Sider } = Layout

export interface DashboardLayoutProps {
  children: React.ReactNode
  header: React.ReactNode
  subHeader?: React.ReactNode
}

const DashboardLayout = ({
  children,
  header,
  subHeader,
}: DashboardLayoutProps) => {
  const location = useLocation()
  const history = useHistory()

  const activeRoot = location.pathname.split('/')[1]

  const onLogout = () => {
    Cookies.remove('ff_session')
    axios
      .post(ENDPOINTS.logout)
      .then(() => {
        history.push('/')
      })
      .catch((e) => {
        console.error('failed logout', e)
      })
  }

  const userMenu = (
    <Menu>
      <Menu.Item onClick={onLogout}>Log out</Menu.Item>
    </Menu>
  )

  return (
    <Layout>
      <Sider breakpoint="lg" collapsedWidth="0">
        <LogoWrapper>
          <Logo fill="white" />
          <Label>Admin</Label>
        </LogoWrapper>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[activeRoot]}
          style={{ paddingTop: 20 }}
        >
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="users" icon={<TeamOutlined />}>
            <Link to="/users">Users</Link>
          </Menu.Item>
          <Menu.Item key="restaurants" icon={<EnvironmentOutlined />}>
            <Link to="/restaurants">Restaurants</Link>
          </Menu.Item>
          <Menu.Item key="tags" icon={<TagsOutlined />}>
            <Link to="/tags">Tags</Link>
          </Menu.Item>
          <Menu.Item key="cuisines" icon={<BookOutlined />}>
            <Link to="/cuisines">Cuisines</Link>
          </Menu.Item>
          <Menu.Item key="interiorDesigns" icon={<HomeOutlined />}>
            <Link to="/interior-designs">Interior designs</Link>
          </Menu.Item>
          <Menu.Item key="other" icon={<QuestionCircleOutlined />}>
            <Link to="/other">Other</Link>
          </Menu.Item>
          <Menu.Item key="tools" icon={<ToolOutlined />}>
            <Link to="/tools">Tools</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="site-layout-header-background"
          style={{ padding: '0px 24px 0px 24px' }}
        >
          <Row style={{ width: '100%' }}>
            <Col
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Title level={3} style={{ margin: 0 }}>
                {header}
              </Title>
            </Col>
            <Col xs={12} style={{ textAlign: 'right' }}>
              <Dropdown overlay={userMenu}>
                <Button type="default" icon={<PoweroffOutlined />} />
              </Dropdown>
            </Col>
          </Row>
        </Header>
        {subHeader && (
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: '8px 48px 8px 48px' }}
          >
            <Row style={{ width: '100%' }}>
              <Col
                xs={24}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                {subHeader}
              </Col>
            </Row>
          </Header>
        )}
        <Content
          style={{
            width: '100%',
            overflow: 'auto',
          }}
        >
          <div
            className="site-layout-background"
            style={{
              marginTop: 24,
              maxWidth: 1200,
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: 24,
              minHeight: 360,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Breadcrumbs />
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
