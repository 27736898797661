import { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useQuery } from '@apollo/client'
import { message } from 'antd'
import { axios } from '../../../../../../clients'
import {
  CuisineDTO,
  InteriorDesignDTO,
  RestaurantDTO,
} from '../../../../../../types/interfaces'
import { ENDPOINTS } from '../../../../../../utils'
import DetailsForm from './DetailsForm'
import { parseRestaurantToPayload } from './helpers'
import { UpdateRestaurantPayload, RestaurantFormData } from './types'
import { INTERIOR_DESIGNS } from '../../../../../../clients/apollo'

const updateRestaurant = (restaurant: UpdateRestaurantPayload) =>
  axios.put<UpdateRestaurantPayload>('restaurants/update', restaurant)

interface Props {
  restaurant: RestaurantDTO
  hasPrimaryPhoto: boolean
}

const Restaurant = ({ restaurant, hasPrimaryPhoto }: Props) => {
  const [enabledFormSection, setEnabledFormSection] =
    useState<string | undefined>()
  const [cuisines, setCuisines] = useState<CuisineDTO[]>([])
  const queryClient = useQueryClient()

  const { isLoading: isSaving, mutate: saveRestaurant } = useMutation(
    updateRestaurant,
    {
      onSuccess: () => {
        message.success(`Updated successfully!`)
        queryClient.invalidateQueries(['restaurant', { id: restaurant.id }])
        setEnabledFormSection(undefined)
      },
      onError: () => {
        message.error(`Something went wrong...`)
      },
    },
  )

  const fetchCuisines = () => {
    axios.get(ENDPOINTS.cuisines).then(({ data }) => {
      setCuisines(data)
    })
  }
  useEffect(() => fetchCuisines(), [])

  const { data } = useQuery<{ interiorDesigns: InteriorDesignDTO[] }>(
    INTERIOR_DESIGNS,
    {
      fetchPolicy: 'cache-and-network',
    },
  )

  const interiorDesigns = data?.interiorDesigns ?? []

  const handleSubmit = async (formData: RestaurantFormData) => {
    const payload = parseRestaurantToPayload(
      formData,
      cuisines,
      interiorDesigns,
    )
    saveRestaurant(payload)
  }

  return (
    <DetailsForm
      restaurant={restaurant}
      isSaving={isSaving}
      onSubmit={handleSubmit}
      cuisines={cuisines}
      mainCuisine={{
        id: restaurant?.mainCuisine?.id ?? '',
        name: restaurant?.mainCuisine?.name ?? '',
      }}
      interiorDesigns={interiorDesigns}
      hasPrimaryPhoto={hasPrimaryPhoto}
      enabledFormSection={enabledFormSection}
      setEnabledFormSection={setEnabledFormSection}
    />
  )
}

export default Restaurant
