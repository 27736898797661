import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Input, Button, Checkbox, Typography, Alert } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { axios } from '../../../clients'
import { ENDPOINTS } from '../../../utils'
import { useHistory, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'

const Container = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Box = styled.div`
  max-width: 300px;
  flex-grow: 1;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const Login = () => {
  const history = useHistory()
  const location = useLocation()
  const [error, setError] = useState(false)

  // TODO figure out location state and remove this ignore
  // @ts-ignore
  const { from } = location.state || { from: { pathname: '/' } }

  const onSubmit = (formValues: any) => {
    const { email, password } = formValues
    axios
      .post(ENDPOINTS.login, { email, password })
      .then(({ data }) => {
        Cookies.set('ff_session', data.ff_session)

        history.replace(from)
      })
      .catch(() => {
        setError(true)
      })
  }

  return (
    <Container>
      <Box>
        <Typography.Title style={{ textAlign: 'center' }}>
          Yumito Admin
        </Typography.Title>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onSubmit}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              autoFocus
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Flex>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
              {/* Or <a href="">register now!</a> */}
            </Form.Item>
          </Flex>
        </Form>
        {error && (
          <Alert message="Incorrect credentials." type="error" showIcon />
        )}
      </Box>
    </Container>
  )
}

export default Login
