import { Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { Card, Form, Select, Input, FormInstance } from 'antd'
import CountryPhoneInput from 'antd-country-phone-input'

import {
  readableProvidersMapping,
  readableRestaurantBadges,
  readableRestaurantFeatures,
} from 'utils'
import {
  getRestuarantCuisinesValidator,
  validateRestaurantNumber,
  validateRestuarantUrl,
} from 'utils/validators'
import { SwitchGroup } from 'components/SwitchGroup'

import {
  CuisineDTO,
  InteriorDesignDTO,
} from '../../../../../../../types/interfaces'
import { RestaurantFormData } from '../types'

const { Option } = Select

const deliveryProviders = [
  'pyszne',
  'glovo',
  'uber',
  'wolt',
  'restaurant',
  'self',
]

interface Props {
  actionButtons: ReactNode
  form: FormInstance<RestaurantFormData>
  cuisines: CuisineDTO[]
  selectedCuisines: string[]
  setSelectedCuisines: Dispatch<SetStateAction<string[]>>
  interiorDesigns: InteriorDesignDTO[]
  isDisabled: boolean
}

const Attributes = ({
  actionButtons,
  form,
  cuisines,
  selectedCuisines,
  setSelectedCuisines,
  interiorDesigns,
  isDisabled,
}: Props) => {
  const [selectedDelivery, setSelectedDelivery] = useState<string[]>(
    form.getFieldValue(['restaurant', 'delivery']),
  )

  return (
    <Card
      size="small"
      title="Attributes"
      style={{ marginBottom: 16 }}
      extra={actionButtons}
    >
      <Form.Item
        name={['restaurant', 'cuisines']}
        label="Cuisines"
        validateTrigger="onChange"
        rules={[getRestuarantCuisinesValidator(form)]}
      >
        <Select
          mode="multiple"
          disabled={isDisabled}
          onChange={(cuisines: string[]) => setSelectedCuisines(cuisines)}
        >
          <Select.OptGroup label="Available cuisines:">
            {cuisines.map((cuisine) => (
              <Option key={cuisine.id} value={cuisine.name}>
                {cuisine.name}
              </Option>
            ))}
          </Select.OptGroup>
        </Select>
      </Form.Item>
      <Form.Item name={['restaurant', 'mainCuisine']} label="Main cuisine">
        <Select disabled={isDisabled}>
          <Select.OptGroup label="Available cuisines:">
            {selectedCuisines.map((cuisine) => (
              <Option key={cuisine} value={cuisine}>
                {cuisine}
              </Option>
            ))}
          </Select.OptGroup>
        </Select>
      </Form.Item>
      <Form.Item
        name={['restaurant', 'interiorDesign']}
        label="Interior design"
      >
        <Select disabled={isDisabled}>
          <Select.OptGroup label="Available interior designs:">
            {interiorDesigns.map((interiorDesign) => (
              <Option key={interiorDesign.id} value={interiorDesign.name}>
                {interiorDesign.name}
              </Option>
            ))}
          </Select.OptGroup>
        </Select>
      </Form.Item>
      <Form.Item label="Delivery" name={['restaurant', 'delivery']}>
        <Select
          mode="multiple"
          disabled={isDisabled}
          onChange={(delivery: string[]) => setSelectedDelivery(delivery)}
        >
          <Select.OptGroup label="Available providers:">
            {deliveryProviders.map((provider) => (
              <Option key={provider} value={provider}>
                {readableProvidersMapping[provider]}
              </Option>
            ))}
          </Select.OptGroup>
        </Select>
      </Form.Item>
      {selectedDelivery
        .filter((name) => !['restaurant', 'self'].includes(name))
        .map((deliveryName) => (
          <Form.Item
            key={deliveryName}
            name={['restaurant', deliveryName, 'url']}
            label={`${readableProvidersMapping[deliveryName]} URL`}
            validateTrigger="onChange"
            rules={[
              {
                required: true,
                message: 'URL is required',
              },
            ]}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
        ))}
      {selectedDelivery.some((name) => name === 'restaurant') && (
        <>
          <Form.Item
            label={`Restaurant's delivery URL:`}
            name={['restaurant', 'restaurant', 'url']}
            rules={[validateRestuarantUrl]}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
          <Form.Item
            label={`Restaurant's delivery number:`}
            name={['restaurant', 'restaurant', 'number']}
            validateTrigger="onChange"
            rules={[validateRestaurantNumber]}
            style={{
              cursor: isDisabled ? 'not-allowed' : 'auto',
            }}
          >
            <CountryPhoneInput
              disabled={isDisabled}
              style={{
                pointerEvents: isDisabled ? 'none' : 'auto',
              }}
            />
          </Form.Item>
        </>
      )}
      <SwitchGroup
        label="Features:"
        formKeys={['restaurant', 'features']}
        mapping={readableRestaurantFeatures}
        disabled={isDisabled}
      />
      <SwitchGroup
        label="Badges:"
        formKeys={['restaurant', 'badges']}
        mapping={readableRestaurantBadges}
        disabled={isDisabled}
      />
    </Card>
  )
}

export default Attributes
